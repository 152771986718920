import { IFetchResponse, IOrganization, IPaginationRequest } from "@/types";
import {
  IOrganizationLocation,
  IOrganizationProvider,
  IOrganizationUnit,
  IOrganizationUser,
} from "@/types/IOrganization";
import { callProfileApi } from "@/api/apiHelper";

export const getUnitDetails = async (unitId: string): Promise<IFetchResponse<IOrganization>> => {
  return await callProfileApi("get", `/units/${unitId}`);
};

export const updateUnit = async (
  unitId: string,
  unit: object
): Promise<IFetchResponse<IOrganizationUnit[]>> => {
  return await callProfileApi("put", `/units/${unitId}`, unit);
};

export const deleteUnit = async (unitId: string): Promise<IFetchResponse<IOrganizationUnit[]>> => {
  return await callProfileApi("delete", `/units/${unitId}`);
};

export const getUnitBreadcrumbs = async (id: string): Promise<IFetchResponse<string[]>> => {
  return await callProfileApi("get", `/units/${id}/breadcrumbs`);
};

export const getUnitChildren = async (
  id: string,
  { keyword }: IPaginationRequest
): Promise<IFetchResponse<IOrganizationUnit[]>> => {
  const params = {
    k: keyword,
  };

  return await callProfileApi("get", `/units/${id}/children`, { params });
};

export const getUnitProviders = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationProvider[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/units/${id}/providers`, {
    params,
  });
};

export const getUnitUsers = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/units/${id}/users`, { params });
};

export const addProvidersToUnit = async (
  unitId: string,
  npiList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationProvider[] }>> => {
  return await callProfileApi("post", `/units/${unitId}/providers`, { ids: npiList });
};

export const removeProvidersFromUnit = async (
  unitId: string,
  npiList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationProvider[] }>> => {
  return await callProfileApi("delete", `/units/${unitId}/providers`, { data: { ids: npiList } });
};

export const addUsersToUnit = async (
  unitId: string,
  npiList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  return await callProfileApi("post", `/units/${unitId}/users`, { ids: npiList });
};

export const removeUsersFromUnit = async (
  unitId: string,
  idList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  return await callProfileApi("delete", `/units/${unitId}/users`, { data: { ids: idList } });
};

export const getUnitLocations = async (
  unitId: string
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  return await callProfileApi("get", `/units/${unitId}/locations`);
};

export const addLocationToUnit = async (
  unitId: string,
  locationId: { organizationLocationId: string }
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  return await callProfileApi("post", `/units/${unitId}/locations`, locationId);
};

export const deleteLocationFromUnit = async (
  unitId: string,
  locationId: string
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  return await callProfileApi("delete", `/units/${unitId}/locations/${locationId}`);
};

export const uploadCsvFile = async (
  unitId: string,
  entity: string,
  file: FormData
): Promise<IFetchResponse> => {
  return await callProfileApi("post", `/units/${unitId}/import-${entity}`, file, {
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const commitImport = async (unitId: string, importId: string): Promise<IFetchResponse> => {
  return await callProfileApi("post", `/units/${unitId}/imports/${importId}/commit`, null, {
    timeout: 0,
  });
};
