import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IProviderProcedure, IPaginationRequest } from "@/types";
import { IProviderProcedureChip } from "@/types/IProvider";

export const getProcedures = async (
  npi: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<IProviderProcedureChip[]>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/provider/${npi}/procedures`, { params });
};

export const getProcedure = async (
  npi: string,
  procedureId: string
): Promise<IFetchResponse<IProviderProcedure>> => {
  return await callProfileApi("get", `/provider/${npi}/procedures/${procedureId}`);
};

export const addProcedure = async (
  npi: string,
  procedure: IProviderProcedure
): Promise<IFetchResponse<IProviderProcedureChip[]>> => {
  return await callProfileApi("post", `/provider/${npi}/procedures`, procedure);
};

export const updateProcedure = async (
  npi: string,
  procedureId: string,
  procedure: IProviderProcedure
): Promise<IFetchResponse<IProviderProcedureChip[]>> => {
  return await callProfileApi("put", `/provider/${npi}/procedures/${procedureId}`, procedure);
};

export const deleteProcedure = async (
  npi: string,
  procedureId: string
): Promise<IFetchResponse<IProviderProcedureChip[]>> => {
  return await callProfileApi("delete", `/provider/${npi}/procedures/${procedureId}`);
};
