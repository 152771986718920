import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IPaginationRequest, IOrganizationGroup } from "@/types";
import IOrganization, {
  IOrganizationLocation,
  IOrganizationProvider,
  IOrganizationToEdit,
  IOrganizationUnit,
  IOrganizationUser,
} from "@/types/IOrganization";
import { IUserReq } from "@/types";
import IProvidersFiltersRequest from "@/types/IProvidersFiltersRequest";

export const addUnit = async (
  id: string,
  unit: object
): Promise<IFetchResponse<IOrganizationUnit[]>> => {
  return await callProfileApi("post", `/organizations/${id}`, unit);
};

export const getUnits = async (id: string): Promise<IFetchResponse<IOrganizationUnit[]>> => {
  return await callProfileApi("get", `/organizations/${id}/units`);
};

export const addProvidersToOrg = async (
  id: string,
  npiList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationProvider[] }>> => {
  return await callProfileApi("post", `/organizations/${id}/providers`, { ids: npiList });
};

export const getOrgProviders = async (
  id: string,
  {
    pageNumber,
    pageSize,
    keyword,
    scope,
    degree,
    experience,
    group,
    language,
    sex,
    specialty,
    unit,
  }: IProvidersFiltersRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationProvider[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
    scope,
    degree,
    experience,
    group,
    language,
    sex,
    specialty,
    unit,
  };

  return await callProfileApi("get", `/organizations/${id}/providers`, {
    params,
  });
};

export const deleteProvidersFromOrg = async (
  id: string,
  npiList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationProvider[] }>> => {
  return await callProfileApi("delete", `/organizations/${id}/providers`, {
    data: { ids: npiList },
  });
};

export const getOrgProvidersFilters = async (id: string): Promise<IFetchResponse> => {
  return await callProfileApi("get", `/organizations/${id}/filter-values/providers`);
};

export const createOrgUser = async (
  id: string,
  user: object
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  return await callProfileApi("post", `/organizations/${id}/users`, user);
};

export const updateOrgUser = async (
  id: string,
  user: IUserReq
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  return await callProfileApi("put", `/organizations/${id}/users/${user.id}`, user);
};

export const getOrgUsers = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/organizations/${id}/users`, { params });
};

export const deleteUsersFromOrg = async (
  id: string,
  idList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  return await callProfileApi("delete", `/organizations/${id}/users`, {
    data: { ids: idList },
  });
};

export const moveUserToUnit = async (
  id: string,
  moveInfo: {
    ids: string[];
    destinationUnitId: string;
  }
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUser[] }>> => {
  return await callProfileApi("put", `/organizations/${id}/users/move`, moveInfo);
};

export const getOrgGroups = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroup[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/organizations/${id}/groups`, { params });
};

export const addGroupToOrg = async (
  id: string,
  group: IOrganizationGroup
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroup[] }>> => {
  return await callProfileApi("post", `/organizations/${id}/groups`, group);
};

export const deleteGroups = async (
  id: string,
  idList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroup[] }>> => {
  return await callProfileApi("delete", `/organizations/${id}/groups`, { data: { ids: idList } });
};

export const getOrgLocations = async (
  id: string,
  { keyword }: IPaginationRequest
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  const params = {
    k: keyword,
  };

  return await callProfileApi("get", `/organizations/${id}/locations`, { params });
};

export const addLocationToOrg = async (
  id: string,
  location: IOrganizationLocation
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  return await callProfileApi("post", `/organizations/${id}/locations`, location);
};

export const getOrgLocation = async (
  id: string,
  locationId: string
): Promise<IFetchResponse<IOrganizationLocation>> => {
  return await callProfileApi("get", `/organizations/${id}/locations/${locationId}`);
};

export const deleteLocationFromOrg = async (
  id: string,
  locationId: string
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  return await callProfileApi("delete", `/organizations/${id}/locations/${locationId}`);
};

export const updateOrgLocation = async (
  id: string,
  locationId: string,
  location: IOrganizationLocation
): Promise<IFetchResponse<IOrganizationLocation[]>> => {
  return await callProfileApi("put", `/organizations/${id}/locations/${locationId}`, location);
};

export const getLastUpload = async (id: string, type: string): Promise<IFetchResponse> => {
  return await callProfileApi("get", `/organizations/${id}/last-import`, {
    params: { type: type },
  });
};

export const updateOrgSettings = async (
  id: string,
  orgToEdit: IOrganizationToEdit
): Promise<IFetchResponse<IOrganization | IOrganizationUnit>> => {
  return await callProfileApi("put", `/units/${id}`, orgToEdit);
};
