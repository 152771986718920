import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 771,
      lg: 1025,
    },
    scrollBarWidth: 0,
  },
  theme: {
    themes: {
      light: {
        primary: "#9126b0",
        secondary: "#1448c2",
        accent: "#69BA99",
        error: "#E2806D",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
});
