import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IInvoice, ISubscriptionPlan } from "@/types";

export const getInvoices = async (): Promise<IFetchResponse<IInvoice[]>> => {
  return await callProfileApi("get", "/invoices");
};

export const getAvailablePlans = async (): Promise<IFetchResponse<ISubscriptionPlan[]>> => {
  return await callProfileApi("get", `/plans`);
};
