<template>
  <v-btn
    :color="color"
    :outlined="outlined"
    elevation="0"
    :height="height"
    :width="width"
    :disabled="disabled"
    :text="text"
    :loading="loading"
    :rounded="rounded"
    :ripple="false"
    :icon="icon"
    :dark="dark"
    fill
    class="app-button"
    @click="$emit('click')"
  >
    <span class="button-text"><slot /></span>
    <slot name="icon" />
  </v-btn>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "AppButton",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: "var(--color-purple-primary)",
    },
    height: {
      type: String,
      required: false,
      default: "56px",
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loader: null,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-button {
  border-radius: 16px;
}

.button-text {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-medium);
  letter-spacing: normal;
  text-transform: none;
}
</style>
