import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IPaginationRequest, IProviderDisease } from "@/types";

export const getDiseases = async (
  npi: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<IProviderDisease[]>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/provider/${npi}/diseases`, { params });
};

export const getDisease = async (
  npi: string,
  diseaseId: string
): Promise<IFetchResponse<IProviderDisease>> => {
  return await callProfileApi("get", `/provider/${npi}/diseases/${diseaseId}`);
};

export const addDisease = async (
  npi: string,
  disease: IProviderDisease
): Promise<IFetchResponse<IProviderDisease[]>> => {
  return await callProfileApi("post", `/provider/${npi}/diseases`, disease);
};

export const updateDisease = async (
  npi: string,
  diseaseId: string,
  disease: IProviderDisease
): Promise<IFetchResponse<IProviderDisease[]>> => {
  return await callProfileApi("put", `/provider/${npi}/diseases/${diseaseId}`, disease);
};

export const deleteDisease = async (
  npi: string,
  diseaseId: string
): Promise<IFetchResponse<IProviderDisease[]>> => {
  return await callProfileApi("delete", `/provider/${npi}/diseases/${diseaseId}`);
};
