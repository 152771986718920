<template>
  <v-dialog
    v-model="isOpen"
    :width="width"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    :content-class="`app-dialog ${contentClass}`"
    persistent
    overlay-color="var(--color-grey-secondary)"
    overlay-opacity="0.6"
  >
    <slot />
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppDialog",
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "600",
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isOpen: this.isDialogOpen,
    };
  },
  watch: {
    isDialogOpen(value: boolean) {
      this.isOpen = value;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.closeDialogHandler);
  },
  destroyed() {
    document.removeEventListener("keydown", this.closeDialogHandler);
  },
  methods: {
    closeDialogHandler(e: KeyboardEvent) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.v-dialog {
  &__content::v-deep .app-dialog {
    position: relative;
    background-color: var(--color-white);
    border-radius: 24px;

    @include breakpoint(xsmall) {
      border-radius: 0;
    }

    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;

      @include breakpoint(xsmall) {
        display: none;
      }
    }
  }
}
</style>
