<template>
  <div class="signup-page">
    <div class="card">
      <h1 class="card-title">Sign up</h1>

      <button class="google-btn" @click="googleLogin">
        <img src="/img/google-logo.svg" alt="" />
        Log in with Google
      </button>

      <img src="/img/pg-signup-logo.png" class="pg-img" alt="" />

      <div class="card-divider">
        <span class="divider-text">Sign up with E-mail</span>
      </div>

      <v-form @submit.prevent="" v-model="isFormValid" class="card-form">
        <AppInput :value.sync="signupData.firstName" :rules="[rules.required]" label="First name" />
        <AppInput :value.sync="signupData.lastName" :rules="[rules.required]" label="Last name" />

        <AppInput
          :value.sync="signupData.email"
          :rules="[rules.required, rules.email]"
          label="E-mail"
          type="email"
        />
        <AppInput
          :value.sync="signupData.password"
          :rules="[rules.required]"
          :type="isShowPassword ? 'text' : 'password'"
          :append-icon="isShowPassword ? mdiEye : mdiEyeOff"
          autocomplete="new-password"
          label="Password"
          @click:append="isShowPassword = !isShowPassword"
        />
        <AppInput
          :value.sync="signupData.confirmPassword"
          :rules="[rules.required]"
          :type="isShowPassword ? 'text' : 'password'"
          :append-icon="isShowPassword ? mdiEye : mdiEyeOff"
          autocomplete="new-password"
          label="Confirm password"
          @click:append="isShowPassword = !isShowPassword"
        />

        <div class="card-terms">
          <v-checkbox
            v-model="signupData.isTermsChecked"
            :rules="[rules.required]"
            hide-details
            class="terms-checkbox"
          />
          <span>
            I’ve read agree with
            <a href="https://patientgenie.com/terms" target="_blank">Terms of Service</a>
            and our
            <a href="https://patientgenie.com/privacy" target="_blank">Privacy Policy</a>
          </span>
        </div>

        <FormErrorMessage :error-message="errorMessage" />

        <AppButton
          :loading="loading"
          @click="signUpWithPassword"
          :disabled="!isFormValid"
          class="submit-btn"
        >
          Get started
        </AppButton>
      </v-form>

      <div class="card-footer">
        <span class="redirect-text">
          Already have a profile? <router-link :to="{ name: 'Login' }">Log in here</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { FirebaseError } from "@firebase/util";
import validationRules from "@/mixins/validation";
import FormErrorMessage from "@/components/FormErrorMessage.vue";
import { mdiClose, mdiEye, mdiEyeOff } from "@mdi/js";
import api from "@/api";

export default Vue.extend({
  name: "SignupPage",
  mixins: [validationRules],
  components: {
    FormErrorMessage,
  },
  data() {
    return {
      mdiClose,
      mdiEye,
      mdiEyeOff,
      isShowPassword: false,
      isFormValid: false,
      loading: false,
      signupData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        isTermsChecked: false,
        confirmPassword: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    async signUpWithPassword() {
      this.errorMessage = "";

      if (this.signupData.password !== this.signupData.confirmPassword) {
        this.errorMessage = "Passwords do not match";
        return;
      }

      this.loading = true;

      try {
        localStorage.setItem(
          "signUpName",
          `${this.signupData.firstName};${this.signupData.lastName}`
        );
        window.localStorage.setItem("signUpEmail", this.signupData.email);

        await createUserWithEmailAndPassword(
          getAuth(),
          this.signupData.email,
          this.signupData.password
        );

        const { error } = await api.user.verificationLink();

        if (error) {
          this.errorMessage = error.text;
          return;
        }

        this.$router.push({ name: "EmailConfirmation" });
      } catch (error: unknown) {
        localStorage.removeItem("signUpName");

        if (!(error instanceof FirebaseError)) {
          this.errorMessage = "Ooops, something went wrong... please try again";
          return;
        }

        if (error.code === "auth/email-already-in-use") {
          this.errorMessage = "There already exists an account with the given email address";
          return;
        }

        if (error.code === "auth/weak-password") {
          this.errorMessage = "Password should be at least 6 characters";
          return;
        }

        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
    async googleLogin() {
      this.errorMessage = "";

      try {
        this.loading = true;
        await signInWithPopup(getAuth(), new GoogleAuthProvider());
      } catch (error: unknown) {
        if (!(error instanceof FirebaseError)) {
          this.errorMessage = "Ooops, something went wrong... please try again";
          return;
        }

        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.signup-page {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 0;

  @include breakpoint(xsmall) {
    align-items: unset;
    margin: 0 -20px;
    padding: 0;
  }

  .card {
    @include sign-card;
  }
}
</style>
