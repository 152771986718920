<template>
  <article
    :class="{ selected: isSelected }"
    class="claim-none-suggest-card"
    @click="$emit('click')"
  >
    <div class="content">
      <div class="information">
        <span class="none-title">None of the above</span>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ClaimSuggestCardNone",
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.claim-none-suggest-card {
  max-width: fit-content;
  padding: 14px 16px 11px;
  background-color: var(--color-white);
  border: var(--border-panel-purple);
  border-radius: 8px;
  cursor: pointer;

  &.selected {
    border: var(--border-panel-green);
  }
}

.content {
  display: flex;
  gap: 44px;
}

.information {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.none-title {
  color: var(--color-grey-primary);
  line-height: 20.8px;
}
</style>
