import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IProvider, IUser } from "@/types";

export const get = async (npi: string): Promise<IFetchResponse<IUser>> => {
  return await callProfileApi("get", `/provider/${npi}`);
};

export const updateProvider = async (
  npi: string,
  entity: object
): Promise<IFetchResponse<IProvider>> => {
  return await callProfileApi("patch", `/provider/${npi}`, entity);
};
