<template>
  <div class="email-verification-page">
    <div class="card">
      <h1 class="card-title">Now check your inbox</h1>

      <button class="close-btn" @click="cancel">
        <v-icon size="20" color="var(--color-grey-primary)">{{ mdiClose }}</v-icon>
      </button>

      <img src="/img/pg-login-greeting.svg" class="pg-img" alt="" />

      <div class="text-content">
        <p>
          We send you a verification mail to <span class="email">{{ userEmail }}</span>
        </p>
        <p>No email? Check your spam folder or try resending.</p>
      </div>

      <FormErrorMessage :error-message="errorMessage" />

      <AppButton :loading="loading" class="submit-btn" @click="resendConfirmationEmail">
        Resend E-mail
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";
import { getAuth, signOut } from "firebase/auth";
import { mdiClose } from "@mdi/js";
import FormErrorMessage from "@/components/FormErrorMessage.vue";

export default Vue.extend({
  name: "EmailConfirmationPage",
  components: {
    FormErrorMessage,
  },
  data() {
    return {
      mdiClose,
      loading: false,
      errorMessage: "",
      userEmail: window.localStorage.getItem("signUpEmail") || "your email",
    };
  },
  methods: {
    async resendConfirmationEmail() {
      this.loading = true;
      const { error } = await api.user.verificationLink();
      this.loading = false;

      if (error) {
        this.errorMessage = error.text;
        return;
      }
    },
    cancel() {
      window.localStorage.removeItem("signUpEmail");
      signOut(getAuth());
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.email-verification-page {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 0;

  @include breakpoint(xsmall) {
    margin: 0 -20px;
    padding: 0;
  }

  .card {
    @include sign-card;

    .card-title {
      max-width: 290px;
      margin-bottom: 24px;
    }

    .text-content {
      max-width: 310px;
      margin-bottom: 44px;
      line-height: 20px;

      .email {
        color: var(--color-purple-primary);
        font-weight: var(--font-weight-bolder);
      }
    }

    .submit-btn {
      width: 100%;
      color: var(--color-white);
    }
  }
}
</style>
