import { IProvider } from "@/types";
import { IOrganizationProvider } from "@/types/IOrganization";

export const getFormattedProviderName = (
  provider: null | IProvider | IOrganizationProvider
): string => {
  if (!provider) return "";

  const formattedName = `${provider.firstName} ${provider.middleName} ${provider.lastName}`;

  if (provider.degrees && provider.degrees.length) {
    return `${formattedName}, ${provider.degrees.join(", ")}`;
  }

  return formattedName;
};
