<template>
  <div class="claim-select-address">
    <div class="step-title">
      <span>{{ step }}. </span>
      <span>Have you ever been associated with any of these addresses?</span>
    </div>

    <div class="suggestions">
      <ClaimSuggestCardAddress
        v-for="address in claimProgress.options"
        :key="address.id"
        :is-selected="getIsSelected(address.id)"
        :address="address"
        class="address-suggest-card"
        @click="selectAddress(address.id)"
      />

      <ClaimSuggestCardNone
        :is-selected="isNoneSelected"
        class="address-suggest-card"
        @click="clearSelected"
      />
    </div>

    <div class="actions">
      <AppButton
        :loading="stepLoading"
        outlined
        width="144px"
        @click="$emit('submit', selectedAddresses)"
      >
        Next
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import ClaimSuggestCardAddress from "@/components/Claim/ClaimSuggestCardAddress.vue";
import ClaimSuggestCardNone from "@/components/Claim/ClaimSuggestCardNone.vue";

import { IClaimProgress } from "@/types";

export default Vue.extend({
  name: "ClaimSelectAddress",
  components: {
    ClaimSuggestCardAddress,
    ClaimSuggestCardNone,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    claimProgress: {
      type: Object as () => IClaimProgress,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedAddresses: [] as string[],
      isNoneSelected: false,
    };
  },
  methods: {
    getIsSelected(id: string) {
      return this.selectedAddresses.includes(id);
    },
    selectAddress(id: string) {
      this.isNoneSelected = false;

      if (!this.getIsSelected(id)) {
        this.selectedAddresses = [...this.selectedAddresses, id];
        return;
      }

      this.selectedAddresses = this.selectedAddresses.filter((addressId) => addressId != id);
    },
    clearSelected() {
      this.selectedAddresses = [];
      this.isNoneSelected = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-select-address {
  .step-title {
    margin-bottom: 50px;
  }

  .field {
    margin-bottom: 64px;
    border-radius: 8px;

    &::v-deep.v-autocomplete.v-select--is-menu-active {
      .v-input__icon--append,
      .v-icon {
        transform: none;
      }
    }
  }

  .suggestions {
    max-height: 500px;
    margin-bottom: 10px;
    overflow-y: auto;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  .address-suggest-card {
    margin-bottom: 24px;
  }
}
</style>
