<template>
  <AppInfoDialog :is-info-dialog-open.sync="isOpen" @close="isOpen = false">
    <template #activator>
      <button @click="isOpen = true">
        <v-icon size="20">{{ mdiLock }}</v-icon>
      </button>
    </template>
    <template #title> <slot name="title" /> </template>
    <template #text> To edit this information, buy a premium account. </template>
  </AppInfoDialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mdiLock } from "@mdi/js";

export default Vue.extend({
  name: "AppSubscriptionOnlyNotice",
  data() {
    return {
      mdiLock,
      isOpen: false,
    };
  },
});
</script>
