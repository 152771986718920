<template>
  <v-select
    v-model="inputValue"
    :items="items"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :type="type"
    :item-value="itemValue"
    :item-text="itemText"
    :clearable="clearable"
    :menu-props="{ offsetY: true, contentClass: menuContentClass }"
    :height="height"
    :dense="dense"
    :disabled="disabled"
    :return-object="returnObject"
    hide-details="auto"
    outlined
    class="app-select"
    @change="$emit('change', $event)"
    @click:clear="$emit('clear')"
  >
    <template #prepend-item>
      <slot name="prepend-item"></slot>
    </template>
  </v-select>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppSelect",
  props: {
    value: {
      type: [String, Number, Object],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      required: false,
      default: "",
    },
    itemValue: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    rules: {
      type: Array,
      required: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      required: false,
      default: 40,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuContentClass: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    inputValue(value) {
      this.$emit("update:value", value);
    },
  },
});
</script>

<style lang="scss" scoped>
.app-select {
  border-radius: 8px;
}
</style>
