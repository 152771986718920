<template>
  <v-app v-if="isAppReady" id="app">
    <TheHeader :class="{ 'auth-header': isClaimOrSignPage }" />

    <v-main class="main">
      <div class="wrapper" :class="{ full: isClaimOrSignPage }">
        <transition mode="out-in" name="fade">
          <router-view />
        </transition>
      </div>
    </v-main>

    <TheFooter :class="{ 'auth-footer': isClaimOrSignPage }" />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";
import { mapActions, mapGetters } from "vuex";
import {
  getAuth,
  onAuthStateChanged,
  updateProfile,
  isSignInWithEmailLink,
  signInWithEmailLink,
  applyActionCode,
  User,
  signInWithCustomToken,
} from "firebase/auth";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

export default Vue.extend({
  name: "App",
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      authRoutes: ["Login", "Signup", "MagicLink"],
      userFB: null as User | null,
      swRefreshing: false,
      swRegistration: null as null | any,
      swUpdateExists: false,
      userEmail: null as null | string,
      isAppReady: false,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
    }),
    isClaimOrSignPage(): boolean {
      return (
        this.$route.name === "ClaimProfile" ||
        this.$route.name === "Login" ||
        this.$route.name === "Signup" ||
        this.$route.name === "MagicLink" ||
        this.$route.name === "EmailConfirmation"
      );
    },
    isNeedRedirectToHomePage(): boolean {
      if (!this.$route.name) return false;
      return this.authRoutes.includes(this.$route.name) || this.$route.name === "ClaimProfile";
    },
    isNeedRedirectToSignUpPage(): boolean {
      if (!this.$route.name) return false;
      return (
        !this.userFB &&
        !this.authRoutes.includes(this.$route.name) &&
        !this.$route.query.organizationId
      );
    },
  },
  created() {
    if ("serviceWorker" in navigator) {
      // Listen for our custom event from the SW registration
      document.addEventListener("swUpdated", this.updateAvailable, { once: true });
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.swRefreshing) return;
        this.swRefreshing = true;
        window.location.reload();
      });
    }
  },
  async mounted() {
    this.checkForNpiQuery();
    this.checkForAuthTokenQuery();

    await this.handleOobCode();

    onAuthStateChanged(getAuth(), async (userFB) => {
      this.userFB = userFB;

      if (this.isNeedRedirectToSignUpPage) {
        this.isAppReady = true;
        this.setUser(null);
        if (this.$route.name !== "Signup") this.$router.push({ name: "Signup" });
        return;
      }

      if (!userFB) {
        this.isAppReady = true;
        return;
      }

      if (userFB && localStorage.getItem("signUpName")) {
        await updateProfile(userFB, { displayName: localStorage.getItem("signUpName") });
        localStorage.removeItem("signUpName");
      }

      if (userFB && !userFB.emailVerified) {
        this.isAppReady = true;
        if (this.$route.name !== "EmailConfirmation") {
          this.$router.push({ name: "EmailConfirmation" });
        }
        return;
      }

      const organizationId = this.$route?.query?.organizationId || "";
      const { data: user } = await api.user.get(organizationId as string);

      if (user) {
        this.setUser(user);

        if (user.subscription === null) await api.user.register();
      }

      if (userFB?.emailVerified && !user?.organization && !user?.provider) {
        this.isAppReady = true;
        if (this.$route.name !== "ClaimProfile") this.$router.push({ name: "ClaimProfile" });
        return;
      }

      if (user?.organization) {
        this.setOrganization(user.organization);
      }

      if (this.isNeedRedirectToHomePage) {
        this.isAppReady = true;
        this.$router.push({ name: "Home" });
        return;
      }

      this.isAppReady = true;
    });
  },
  methods: {
    ...mapActions(["setUser"]),
    ...mapActions("provider", ["setProvider"]),
    ...mapActions("organization", ["setOrganization"]),
    updateAvailable(event: any) {
      // Store the SW registration
      this.swRegistration = event.detail;
      this.swUpdateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.swUpdateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.swRegistration || !this.swRegistration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.swRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    checkForNpiQuery() {
      const urlParams = new URLSearchParams(window.location.search);
      const npi = urlParams.get("npi");

      if (npi) localStorage.setItem("npi", npi);
    },
    checkForAuthTokenQuery() {
      const urlQuery = new URLSearchParams(window.location.search);
      const authToken = urlQuery.get("token");

      if (authToken) {
        signInWithCustomToken(getAuth(), authToken);
      }
    },
    handleOobCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const queryMode = urlParams.get("mode");
      if (!queryMode) return;

      const queryEmail = urlParams.get("email") || "";
      const oobCode = urlParams.get("oobCode") || "";

      switch (queryMode) {
        case "signIn":
          this.signInWithMagicLink(queryEmail);
          break;
        case "verifyEmail":
          this.verifyEmail(oobCode);
      }
    },
    async signInWithMagicLink(queryEmail: string) {
      if (this.getUser || !isSignInWithEmailLink(getAuth(), window.location.href)) return;

      try {
        await signInWithEmailLink(getAuth(), queryEmail, window.location.href);
      } catch (error) {
        console.error(error);
      } finally {
        this.$router.push({ name: "Home" });
      }
    },
    async verifyEmail(oobCode: string) {
      try {
        await applyActionCode(getAuth(), oobCode);
        window.localStorage.removeItem("signUpEmail");
      } catch (error) {
        console.error(error);
      } finally {
        if (this.userFB) {
          window.location.href = `${window.location.origin}/claim-profile`;
        }

        window.location.href = `${window.location.origin}/login`;
      }
    },
  },
});
</script>

<style lang="scss">
@import "@/assets/style/global";
@import "@/assets/style/mixins";

#app {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0%;
  }

  .main {
    background-color: var(--color-purple-light);
  }

  .wrapper {
    @include container-large;

    height: 100%;

    &.full {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .auth-header,
  .auth-footer {
    background-color: var(--color-purple-light);
  }
}
</style>
