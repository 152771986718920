<template>
  <div class="claim-find-profile">
    <div class="step-title">
      <span>{{ step }}. </span>
      <span>Enter your first and last name</span>
    </div>

    <v-combobox
      :search-input.sync="search"
      :items="doctors"
      v-model="selectedDoctor"
      label="Name"
      outlined
      :append-icon="mdiMagnify"
      class="field"
      return-object
      hide-no-data
      item-text="name"
      no-filter
      @keydown.enter.prevent="submitStep"
    >
      <template #selection="{ item }">
        {{ item.name || item }}
      </template>
      <template #item="{ item }">
        <ClaimDoctorSuggestCard :doctor="item" class="suggest-card" />
      </template>
    </v-combobox>

    <div class="actions">
      <AppButton
        :loading="stepLoading"
        :disabled="!getIsNextButtonEnabled"
        outlined
        width="144px"
        @click="submitStep"
      >
        Next
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";

import ClaimSuggestCardDoctor from "@/components/Claim/ClaimSuggestCardDoctor.vue";
import { IClaimProgressOptionCard, IDebounce } from "@/types";
import { mdiMagnify } from "@mdi/js";

export default Vue.extend({
  name: "ClaimFindProfile",
  components: {
    ClaimDoctorSuggestCard: ClaimSuggestCardDoctor,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiMagnify,
      doctors: [] as IClaimProgressOptionCard[],
      selectedDoctor: null as null | IClaimProgressOptionCard,
      keyword: "",
      debounce: {
        timer: null,
        timeout: 250,
      } as IDebounce,
    };
  },
  watch: {
    keyword() {
      if (this.keyword.trim().length < 3) return;
      if (this.debounce.timer) clearTimeout(this.debounce.timer);

      this.debounce.timer = setTimeout(async () => {
        await this.fetchProviders();
        this.debounce.timer = null;
      }, this.debounce.timeout);
    },
  },
  computed: {
    search: {
      get(): string {
        return this.keyword;
      },
      set(searchInput: string) {
        if (!searchInput) {
          this.doctors = [];
          return;
        }

        this.keyword = searchInput;
      },
    },
    getIsNextButtonEnabled(): boolean {
      return !!this.selectedDoctor?.id || this.keyword?.length > 3;
    },
  },
  methods: {
    async fetchProviders() {
      const { data } = await api.claim.findProvider(this.keyword);
      if (data) this.doctors = data;
    },
    submitStep() {
      if (this.selectedDoctor?.id) {
        this.$emit("submit", [this.selectedDoctor.id]);
        return;
      }

      this.$emit("submit", [this.keyword]);
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-find-profile {
  .step-title {
    margin-bottom: 50px;
  }

  .field {
    margin-bottom: 64px;
    border-radius: 8px;

    &::v-deep.v-autocomplete.v-select--is-menu-active {
      .v-input__icon--append,
      .v-icon {
        transform: none;
      }
    }
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}

.suggest-card {
  width: min-content;
  padding: 10px 5px;
  border: none;
}
</style>
