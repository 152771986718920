import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IFocusArea } from "@/types";

export const getFocusAreas = async (npi: string): Promise<IFetchResponse<IFocusArea[]>> => {
  return await callProfileApi("get", `/provider/${npi}/focus-areas`);
};

export const addFocusArea = async (
  npi: string,
  focusAreaId: string,
  focusArea: IFocusArea
): Promise<IFetchResponse<IFocusArea[]>> => {
  return await callProfileApi("post", `/provider/${npi}/focus-areas/${focusAreaId}`, focusArea);
};

export const deleteFocusArea = async (
  npi: string,
  focusAreaId: string
): Promise<IFetchResponse<IFocusArea[]>> => {
  return await callProfileApi("delete", `/provider/${npi}/focus-areas/${focusAreaId}`);
};
