import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import { IOrganizationUser, IOrganizationUnit } from "@/types/IOrganization";
import IOrganizationGroup from "@/types/IOrganizationGroup";

export const getUserInfo = async (
  orgId: string,
  id: string
): Promise<IFetchResponse<IOrganizationUser>> => {
  return await callProfileApi("get", `/organizations/${orgId}/users/${id}`);
};

export const getUserGroups = async (
  orgId: string,
  id: string
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroup[] }>> => {
  return await callProfileApi("get", `/organizations/${orgId}/users/${id}/groups`);
};

export const getUserUnits = async (
  orgId: string,
  id: string
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUnit[] }>> => {
  return await callProfileApi("get", `/organizations/${orgId}/users/${id}/units`);
};
