import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IEducation } from "@/types";

export const getEducations = async (npi: string): Promise<IFetchResponse<IEducation[]>> => {
  return await callProfileApi("get", `/provider/${npi}/educations`);
};

export const getEducation = async (
  npi: string,
  educationId: string
): Promise<IFetchResponse<IEducation>> => {
  return await callProfileApi("get", `/provider/${npi}/educations/${educationId}`);
};

export const addEducation = async (
  npi: string,
  education: IEducation
): Promise<IFetchResponse<IEducation[]>> => {
  return await callProfileApi("post", `/provider/${npi}/educations`, education);
};

export const updateEducation = async (
  npi: string,
  educationId: string,
  education: IEducation
): Promise<IFetchResponse<IEducation[]>> => {
  return await callProfileApi("put", `/provider/${npi}/educations/${educationId}`, education);
};

export const deleteEducation = async (
  npi: string,
  educationId: string
): Promise<IFetchResponse<IEducation[]>> => {
  return await callProfileApi("delete", `/provider/${npi}/educations/${educationId}`);
};
