import * as claim from "@/api/claim";
import * as user from "@/api/user";
import * as provider from "@/api/provider";
import * as glossary from "@/api/glossary";
import * as diseases from "@/api/diseases";
import * as procedures from "@/api/procedures";
import * as locations from "@/api/locations";
import * as focusAreas from "@/api/focusAreas";
import * as education from "@/api/education";
import * as insurances from "@/api/insurances";
import * as analytics from "@/api/analytics";
import * as organizations from "@/api/organization";
import * as groups from "@/api/groups";
import * as orgProvider from "@/api/orgProvider";
import * as orgAuthUser from "@/api/orgAuthUser";
import * as orgUnit from "@/api/orgUnit";
import * as invoice from "@/api/invoices";
import * as actionLinks from "@/api/actionLinks";

export default {
  claim,
  user,
  provider,
  glossary,
  diseases,
  procedures,
  locations,
  focusAreas,
  education,
  insurances,
  analytics,
  organizations,
  groups,
  orgProvider,
  orgAuthUser,
  orgUnit,
  invoice,
  actionLinks,
};
