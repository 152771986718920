<template>
  <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
</template>

<script>
export default {
  name: "FormErrorMessage",
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped>
.error-message {
  margin: 0;
  color: var(--color-peach);
  text-align: center;
}
</style>
