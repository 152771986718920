<template>
  <div v-if="claimProgress" class="claim-profile-page">
    <div class="steps">
      <button
        v-for="(_, index) in stepsComponents"
        :key="index"
        class="step-btn"
        :class="{ active: index + 1 === claimProgress.step }"
      >
        {{ index + 1 }}
      </button>
    </div>
    <div class="step-view">
      <button class="close-btn" @click="isCancelClaimDialogOpen = true">
        <v-icon size="20" color="var(--color-grey-primary)">{{ mdiClose }}</v-icon>
      </button>

      <h1 class="step-view-title">Claim your profile</h1>

      <transition
        name="slide"
        enter-active-class="animated-transition"
        :enter-class="transitionEnterClass"
      >
        <keep-alive>
          <component
            :is="getActiveComponent"
            :step="currentStep"
            :step-loading="stepLoading"
            :claim-progress="claimProgress"
            @submit="submitClaim"
            @confirm="confirmClaim"
            @reset="resetClaim"
          />
        </keep-alive>
      </transition>
    </div>

    <v-dialog v-model="isCancelClaimDialogOpen" max-width="443" content-class="cancel-claim-dialog">
      <h2 class="dialog-title">Are you sure you want to close the form, all data will be lost?</h2>
      <div class="actions">
        <AppButton width="144px" outlined @click="cancelClaim">Yes</AppButton>
        <AppButton
          width="144px"
          outlined
          color="var(--color-blue-primary)"
          @click="isCancelClaimDialogOpen = false"
        >
          No
        </AppButton>
      </div>
    </v-dialog>

    <AppInfoDialog :is-info-dialog-open.sync="isInfoDialogOpen" @close="isInfoDialogOpen = false">
      <template #title>Claim your profile</template>
      <template #text>
        {{ claimProgress.failureMessage }}
      </template>
    </AppInfoDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api/index";
import { mapActions } from "vuex";

import ClaimFindProfile from "@/components/Claim/ClaimFindProfile.vue";
import ClaimSelectProfile from "@/components/Claim/ClaimSelectProfile.vue";
import ClaimSelectAddress from "@/components/Claim/ClaimSelectAddress.vue";
import ClaimSelectEducation from "@/components/Claim/ClaimSelectEducation.vue";
import ClaimConfirmation from "@/components/Claim/ClaimConfirmation.vue";
import { IClaimProgress } from "@/types";
import { mdiClose } from "@mdi/js";

export default Vue.extend({
  name: "ClaimProfile",
  components: {
    ClaimFindProfile,
    ClaimSelectProfile,
    ClaimSelectAddress,
    ClaimSelectEducation,
    ClaimConfirmation,
  },
  data() {
    return {
      mdiClose,
      isCancelClaimDialogOpen: false,
      isInfoDialogOpen: false,
      claimProgress: null as IClaimProgress | null,
      stepsComponents: [
        "ClaimFindProfile",
        "ClaimSelectProfile",
        "ClaimSelectAddress",
        "ClaimSelectEducation",
        "ClaimConfirmation",
      ],
      transitionEnterClass: "",
      stepLoading: false,
    };
  },
  computed: {
    getActiveComponent(): string {
      return this.claimProgress ? this.stepsComponents[this.claimProgress.step - 1] : "";
    },
    currentStep(): number {
      return this.claimProgress?.step || 0;
    },
  },
  watch: {
    currentStep(newVal, oldVal) {
      this.transitionEnterClass = newVal > oldVal ? "slide-in-right" : "slide-in-left";
    },
  },
  mounted() {
    this.startClaim();
  },
  methods: {
    ...mapActions("provider", ["setProvider"]),
    resetClaim() {
      localStorage.removeItem("npi");
      this.startClaim();
    },
    async startClaim() {
      const npi = localStorage.getItem("npi") || "";

      const { data } = await api.claim.start(npi);
      if (data) this.claimProgress = data;
    },
    async submitClaim(selectedOptions: string[]) {
      if (!this.claimProgress?.id) return;
      this.stepLoading = true;
      const { data } = await api.claim.update(this.claimProgress.id, {
        responses: selectedOptions,
      });
      this.stepLoading = false;

      if (data) {
        this.claimProgress = data;
        if (!this.claimProgress.failureMessage) return;

        this.isInfoDialogOpen = true;
        localStorage.removeItem("npi");
      }
    },
    async confirmClaim() {
      if (!this.claimProgress) return;

      this.stepLoading = true;
      const { data } = await api.claim.confirm(this.claimProgress.id);
      this.stepLoading = false;

      localStorage.removeItem("npi");

      if (data) {
        this.setProvider(data);
        await this.$router.push("Profile");
      }
    },
    cancelClaim() {
      location.href = process.env.VUE_APP_SEARCH_URL;
      localStorage.removeItem("npi");
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.claim-profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 44px;

  .animated-transition {
    transition: opacity 0.5s linear, transform 0.3s ease-in-out;
  }

  .slide-in-left {
    transform: translate(-100%, 0);
    opacity: 0%;
  }

  .slide-in-right {
    transform: translate(100%, 0);
    opacity: 0%;
  }

  .steps {
    display: flex;
    gap: 44px;
    margin-bottom: 64px;

    @include breakpoint(xsmall) {
      gap: 24px;
    }
  }

  .step-btn {
    position: relative;
    width: 44px;
    height: 44px;
    color: var(--color-grey-secondary);
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-big);
    background-image: linear-gradient(#ecf0ff, #ecf0ff), linear-gradient(to right, #9227b1, #00aeef);
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    border-radius: 50%;

    &.active {
      color: var(--color-white);
      background: linear-gradient(180deg, #9227b1 0%, #9227b1 26.3%, #00aeef 100%);
      border: 1px solid;
    }

    &:not(:first-of-type)::before {
      position: absolute;
      top: 50%;
      left: calc(-100% - 3px);
      width: 44px;
      height: 1px;
      background-color: var(--color-white);
      content: "";

      @include breakpoint(xsmall) {
        left: calc(100% - 67px);
        width: 24px;
      }
    }
  }

  .step-view {
    position: relative;
    width: 100%;
    max-width: 638px;
    padding: 44px;
    overflow: hidden;
    background-color: var(--color-white);
    border-radius: var(--default-border-radius);

    @include breakpoint(xsmall) {
      padding: 24px;
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
    }

    .step-view-title {
      margin-bottom: 24px;
      color: var(--color-purple-primary);
      font-size: var(--font-heading-big);
      line-height: 1.3;
    }
  }
}

.v-dialog__content::v-deep .cancel-claim-dialog {
  padding: 24px;
  background-color: var(--color-white);
  border-radius: var(--default-border-radius);

  .dialog-title {
    margin-bottom: 44px;
    font-size: var(--font-big);
    text-align: center;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}
</style>
