<template>
  <v-breadcrumbs :items="breadCrumbs" divider="›" class="base-breadcrumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to || { params: { id: item.id } }"
        :exact="item.exact"
        class="breadcrumb-item"
        :class="{ active: item.id ? $route.params.id === item.id : $route.name === item.routeName }"
      >
        {{ item.name || item }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from "vuex";
import { APP_VIEW } from "@/static/constants";

export default {
  name: "AppBreadcrumbs",
  computed: {
    ...mapGetters(["getCurrentView"]),
    ...mapGetters("organization", ["getOrgBreadcrumbs"]),
    breadCrumbs() {
      if (!this.$route.meta?.breadCrumb) return [];

      if (typeof this.$route.meta.breadCrumb !== "function") {
        return this.$route.meta.breadCrumb;
      }

      if (this.getCurrentView === APP_VIEW.organization && this.getOrgBreadcrumbs.length) {
        return [...this.$route.meta?.breadCrumb.call(this, this.$route), ...this.getOrgBreadcrumbs];
      }

      return this.$route.meta?.breadCrumb.call(this, this.$route);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-breadcrumbs {
  display: flex;
  align-items: baseline;

  &::v-deep {
    .v-breadcrumbs__divider {
      padding: 0 10px;
      font-weight: var(--font-weight-bold);
      transform: scale(1.2);
    }

    .breadcrumb-item {
      .v-breadcrumbs__item {
        color: var(--color-grey-tertiary);
        font-size: var(--font-extra-small);
      }

      &.active .v-breadcrumbs__item {
        color: var(--color-purple-primary);
      }
    }
  }
}
</style>
