<template>
  <div class="claim-select-education">
    <div class="step-title">
      <span>{{ step }}. </span>
      <span>Have you graduated from any of these institutions?</span>
    </div>

    <div class="suggestions">
      <ClaimSuggestCardEducation
        v-for="education in claimProgress.options"
        :key="education.id"
        :is-selected="getIsSelected(education.id)"
        :education="education"
        class="education-suggest-card"
        @click="selectEducation(education.id)"
      />

      <ClaimSuggestCardNone
        :is-selected="isNoneSelected"
        class="education-suggest-card"
        @click="clearSelected"
      />
    </div>

    <div class="actions">
      <AppButton
        :loading="stepLoading"
        outlined
        width="144px"
        @click="$emit('submit', selectedEducations)"
      >
        Next
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import ClaimSuggestCardEducation from "@/components/Claim/ClaimSuggestCardEducation.vue";
import ClaimSuggestCardNone from "@/components/Claim/ClaimSuggestCardNone.vue";

import { IClaimProgress } from "@/types";

export default Vue.extend({
  name: "ClaimSelectEducation",
  components: {
    ClaimSuggestCardEducation,
    ClaimSuggestCardNone,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    claimProgress: {
      type: Object as () => IClaimProgress,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedEducations: [] as string[],
      isNoneSelected: false,
    };
  },
  methods: {
    getIsSelected(id: string) {
      return this.selectedEducations.includes(id);
    },
    selectEducation(id: string) {
      this.isNoneSelected = false;

      if (!this.getIsSelected(id)) {
        this.selectedEducations = [...this.selectedEducations, id];
        return;
      }

      this.selectedEducations = this.selectedEducations.filter((educationId) => educationId != id);
    },
    clearSelected() {
      this.selectedEducations = [];
      this.isNoneSelected = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-select-education {
  .step-title {
    margin-bottom: 50px;
  }

  .field {
    margin-bottom: 64px;
    border-radius: 8px;

    &::v-deep.v-autocomplete.v-select--is-menu-active {
      .v-input__icon--append,
      .v-icon {
        transform: none;
      }
    }
  }

  .suggestions {
    max-height: 500px;
    margin-bottom: 10px;
    overflow-y: auto;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  .education-suggest-card {
    margin-bottom: 24px;
  }
}
</style>
