import { render, staticRenderFns } from "./ClaimSelectAddress.vue?vue&type=template&id=5b8860e8&scoped=true"
import script from "./ClaimSelectAddress.vue?vue&type=script&lang=ts"
export * from "./ClaimSelectAddress.vue?vue&type=script&lang=ts"
import style0 from "./ClaimSelectAddress.vue?vue&type=style&index=0&id=5b8860e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8860e8",
  null
  
)

export default component.exports