/* eslint-disable no-console */

import { register } from "register-service-worker";

// if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  registered() {
    console.log("Service worker has been registered.");
  },
  updated(registration) {
    console.log("New content is available; please refresh.");
    // When the SW is updated we will dispatch an event we can listen to in our .vue file
    document.dispatchEvent(new CustomEvent("swUpdated", { detail: registration }));
  },
});
// }
