<template>
  <article
    :class="{ selected: isSelected }"
    @click="$emit('click')"
    class="claim-address-suggest-card"
  >
    <div class="content">
      <div class="information">
        <div class="address-info-list">
          <div class="info-list-item">
            {{ address.name }}
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import Vue from "vue";
import { IClaimProgressOptionCard } from "@/types";

export default Vue.extend({
  name: "ClaimSuggestCardAddress",
  props: {
    address: {
      type: Object as () => IClaimProgressOptionCard,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.claim-address-suggest-card {
  max-width: fit-content;
  padding: 14px 16px 11px;
  background-color: var(--color-white);
  border: var(--border-panel-purple);
  border-radius: 8px;
  cursor: pointer;

  &.selected {
    border: var(--border-panel-green);
  }
}

.content {
  display: flex;
  gap: 44px;
}

.information {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.address-info-list {
  padding: 0;
  overflow: hidden;
  color: var(--color-grey-primary);
  line-height: 20.8px;
}
</style>
