export default [
  {
    path: "/profile/:id?",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "ProviderAdmin" */ "../views/ProviderAdmin/ProviderAdmin.vue"),
    redirect: { name: "ProfilePreview" },
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          name: "Provider details",
        },
      ],
    },
    children: [
      {
        path: "profile-preview",
        name: "ProfilePreview",
        component: () =>
          import(
            /* webpackChunkName: "ProviderPreview" */ "../views/ProviderAdmin/ProviderPreview.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
              },
              {
                routeName: "ProfilePreview",
                name: "Profile preview",
              },
            ];
          },
        },
      },
      {
        path: "provider-information",
        name: "ProviderInformation",
        component: () =>
          import(/* webpackChunkName: "ProviderInfo" */ "../views/ProviderAdmin/ProviderInfo.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "ProviderInformation",
                name: "About",
              },
            ];
          },
        },
      },
      {
        path: "languages",
        name: "Languages",
        component: () =>
          import(/* webpackChunkName: "LanguageList" */ "../views/ProviderAdmin/LanguageList.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "Languages",
                name: "Languages",
              },
            ];
          },
        },
      },
      {
        path: "location",
        name: "Location",
        component: () =>
          import(/* webpackChunkName: "LocationList" */ "../views/ProviderAdmin/LocationList.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "Location",
                name: "Location",
              },
            ];
          },
        },
      },
      {
        path: "scores",
        name: "Scores",
        component: () =>
          import(/* webpackChunkName: "ScoresList" */ "../views/ProviderAdmin/ScoresList.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "Scores",
                name: "Scores",
              },
            ];
          },
        },
      },
      {
        path: "insurances",
        name: "Insurances",
        component: () =>
          import(
            /* webpackChunkName: "InsuranceList" */ "../views/ProviderAdmin/InsuranceList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "Insurances",
                name: "Insurances",
              },
            ];
          },
        },
      },
      {
        path: "focus-area",
        name: "FocusArea",
        component: () =>
          import(
            /* webpackChunkName: "FocusAreaList" */ "../views/ProviderAdmin/FocusAreaList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "FocusArea",
                name: "Focus areas",
              },
            ];
          },
        },
      },
      {
        path: "panel-demographics",
        name: "PanelDemographics",
        component: () =>
          import(
            /* webpackChunkName: "PanelDemographics" */ "../views/ProviderAdmin/PanelDemographics.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "PanelDemographics",
                name: "Panel demographics",
              },
            ];
          },
        },
      },
      {
        path: "conditions-and-diseases",
        name: "ConditionsDiseases",
        component: () =>
          import(/* webpackChunkName: "DiseaseList" */ "../views/ProviderAdmin/DiseaseList.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "ConditionsDiseases",
                name: "Conditions and diseases",
              },
            ];
          },
        },
      },
      {
        path: "treatments-and-procedures",
        name: "TreatmentsProcedures",
        component: () =>
          import(
            /* webpackChunkName: "ProcedureList" */ "../views/ProviderAdmin/ProcedureList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "TreatmentsProcedures",
                name: "Treatments and procedures",
              },
            ];
          },
        },
      },
      {
        path: "education",
        name: "Education",
        component: () =>
          import(
            /* webpackChunkName: "EducationList" */ "../views/ProviderAdmin/EducationList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "Education",
                name: "Education",
              },
            ];
          },
        },
      },
      {
        path: "online-profiles",
        name: "OnlineProfiles",
        component: () =>
          import(
            /* webpackChunkName: "OnlineProfileList" */ "../views/ProviderAdmin/OnlineProfileList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "OnlineProfiles",
                name: "Online profiles",
              },
            ];
          },
        },
      },
      {
        path: "plan-upgrade",
        name: "PlanUpgrade",
        component: () =>
          import(/* webpackChunkName: "PlanUpgrade" */ "../views/ProviderAdmin/PlanUpgrade.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "PlanUpgrade",
                name: "Plan upgrade",
              },
            ];
          },
        },
      },
      {
        path: "my-plan",
        name: "MyPlan",
        component: () =>
          import(/* webpackChunkName: "MyPlan" */ "../views/ProviderAdmin/MyPlan.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "MyPlan",
                name: "My plan",
              },
            ];
          },
        },
      },
      {
        path: "analytics",
        name: "ProviderAnalytics",
        component: () =>
          import(
            /* webpackChunkName: "ProviderAnalytics" */ "../views/ProviderAdmin/ProviderAnalytics.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                routeName: "ProviderAnalytics",
                name: "Analytics",
              },
            ];
          },
        },
      },
      {
        path: "analytics/sex",
        name: "ProviderAnalyticsSex",
        component: () =>
          import(
            /* webpackChunkName: "ProviderAnalyticsSex" */ "../views/ProviderAdmin/ProviderAnalyticsSex.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                to: { name: "ProviderAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "ProviderAnalyticsSex",
                name: "Sex",
              },
            ];
          },
        },
      },
      {
        path: "analytics/age",
        name: "ProviderAnalyticsAge",
        component: () =>
          import(
            /* webpackChunkName: "ProviderAnalyticsAge" */ "../views/ProviderAdmin/ProviderAnalyticsAge.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                to: { name: "ProviderAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "ProviderAnalyticsAge",
                name: "Age",
              },
            ];
          },
        },
      },
      {
        path: "analytics/city",
        name: "ProviderAnalyticsCity",
        component: () =>
          import(
            /* webpackChunkName: "ProviderAnalyticsCity" */ "../views/ProviderAdmin/ProviderAnalyticsCity.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                to: { name: "ProviderAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "ProviderAnalyticsCity",
                name: "City",
              },
            ];
          },
        },
      },
      {
        path: "analytics/keyword",
        name: "ProviderAnalyticsKeyword",
        component: () =>
          import(
            /* webpackChunkName: "ProviderAnalyticsKeyword" */ "../views/ProviderAdmin/ProviderAnalyticsKeyword.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Provider details",
                to: { name: "Profile" },
                exact: true,
              },
              {
                to: { name: "ProviderAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "ProviderAnalyticsKeyword",
                name: "Keyword",
              },
            ];
          },
        },
      },
    ],
  },
];
