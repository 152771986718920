<template>
  <footer class="footer">
    <div class="container">
      <span class="copy"> © PatientGenie, Inc. {{ currentYear }} </span>
    </div>
  </footer>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "TheFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.footer {
  text-align: center;
  background-color: var(--color-white);

  .container {
    @include container-large;

    padding-top: 20px;
    padding-bottom: 20px;
  }
}
</style>
