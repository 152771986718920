<template>
  <v-autocomplete
    v-model="inputValue"
    :items="items"
    :label="label"
    :append-icon="appendIcon"
    :item-value="itemValue"
    :item-text="itemText"
    :multiple="multiple"
    :chips="chips"
    :deletable-chips="deletableChips"
    outlined
    return-object
    hide-details="auto"
    class="app-autocomplete"
  />
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppAutocomplete",
  props: {
    value: {
      required: true,
    },
    items: {
      type: [Array, Object],
      required: true,
    },
    itemValue: {
      type: String,
      required: false,
      default: "value",
    },
    itemText: {
      type: String,
      required: false,
      default: "display",
    },
    label: {
      type: String,
      required: true,
    },
    appendIcon: {
      type: String,
      required: false,
      default: "",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(value) {
      if (typeof value === "object") {
        this.$emit("update:value", value[this.itemValue]);
        return;
      }

      this.$emit("update:value", value);
    },
  },
});
</script>

<style lang="scss" scoped>
.app-autocomplete {
  border-radius: 8px;

  &::v-deep.v-autocomplete.v-select--is-menu-active {
    .v-input__icon--append,
    .v-icon {
      transform: none;
    }
  }

  &::v-deep .v-select__selections {
    min-height: 60px !important;

    .v-chip {
      color: var(--color-purple-primary);
      background-color: rgb(145 38 176 / 5%);
      border-radius: 8px;
    }
  }
}
</style>
