<template>
  <div class="claim-select-profile">
    <div class="step-title">
      <span>{{ step }}. </span>
      <span>Is this your profile?</span>
    </div>

    <template v-if="claimProgress.options.length === 1">
      <div class="doctor-card-wrapper">
        <ClaimDoctorSuggestCard :doctor="claimProgress.options[0]" />
      </div>

      <div class="actions">
        <AppButton
          :loading="stepLoading"
          outlined
          width="144px"
          @click="$emit('submit', [claimProgress.options[0].id])"
        >
          Yes
        </AppButton>
        <AppButton outlined width="144px" color="var(--color-blue-primary)" @click="$emit('reset')">
          No
        </AppButton>
      </div>
    </template>

    <section class="multiple-suggestions" v-if="claimProgress.options.length > 1">
      <ClaimDoctorSuggestCard
        :key="doctor.id"
        v-for="doctor in claimProgress.options"
        :doctor="doctor"
        @click="$emit('submit', [doctor.id])"
        class="doctor-card"
      />
    </section>

    <template v-if="!claimProgress.options.length">
      <p>Not found</p>
      <div class="actions">
        <AppButton outlined width="144px" color="var(--color-blue-primary)" @click="$emit('reset')">
          Back to search
        </AppButton>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import ClaimDoctorSuggestCard from "@/components/Claim/ClaimSuggestCardDoctor.vue";
import { IClaimProgress } from "@/types";

export default Vue.extend({
  name: "ClaimSelectProfile",
  components: {
    ClaimDoctorSuggestCard,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    claimProgress: {
      type: Object as () => IClaimProgress,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-select-profile {
  .step-title {
    margin-bottom: 50px;
  }

  .doctor-card-wrapper {
    margin-bottom: 64px;
  }

  .multiple-suggestions {
    max-height: 500px;
    margin-bottom: 10px;
    overflow-y: auto;
  }

  .doctor-card {
    margin-bottom: 10px;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}
</style>
