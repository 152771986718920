import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, ICpt, IIcd, IFocusArea, IEducation } from "@/types";
import { IOrganizationProvider } from "@/types/IOrganization";

const glossaryUrl = "/glossary";

export const getCptList = async (keyword: string): Promise<IFetchResponse<ICpt[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/cpts`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getIcdList = async (keyword: string): Promise<IFetchResponse<IIcd[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/icds`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getFocusAreas = async (keyword: string): Promise<IFetchResponse<IFocusArea[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/focus-areas`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getEducations = async (keyword: string): Promise<IFetchResponse<IEducation[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/educations`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getProviders = async (
  keyword: string
): Promise<IFetchResponse<IOrganizationProvider[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/providers`, {
    params: {
      keyword,
    },
  });
};
