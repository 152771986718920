<template>
  <v-combobox
    :items="items"
    :label="label"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    :multiple="multiple"
    :chips="chips"
    height="60px"
    hide-details="auto"
    outlined
    class="app-combobox"
  />
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppCombobox",
  props: {
    items: {
      type: [Array, Object],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    appendIcon: {
      type: String,
      required: false,
      default: "",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.app-combobox {
  border-radius: 8px;

  &::v-deep.v-autocomplete.v-select--is-menu-active {
    .v-input__icon--append,
    .v-icon {
      transform: none;
    }
  }

  &::v-deep .v-select__selections {
    .v-chip {
      color: var(--color-purple-primary);
      background-color: rgb(145 38 176 / 5%);
      border-radius: 8px;
    }
  }
}
</style>
