export default [
  {
    path: "/organization",
    name: "OrganizationAdmin",
    component: () =>
      import(
        /* webpackChunkName: "OrganizationAdmin" */ "../views/OrganizationAdmin/OrganizationAdmin.vue"
      ),
    redirect: { name: "OrganizationalUnits" },
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          name: "Organization",
        },
      ],
    },
    children: [
      {
        path: "organizational-units",
        name: "OrganizationalUnits",
        component: () =>
          import(/* webpackChunkName: "UnitList" */ "../views/OrganizationAdmin/UnitList.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
              },
              {
                routeName: "OrganizationalUnits",
                name: "Organizational units",
              },
            ];
          },
        },
      },
      {
        path: "organizational-units/:id",
        name: "OrganizationalSingleUnit",
        component: () =>
          import(
            /* webpackChunkName: "UnitDetails" */ "../views/OrganizationAdmin/UnitDetails.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                to: { name: "OrganizationAdmin" },
                name: "Organization",
                exact: true,
              },
              {
                to: { name: "OrganizationalUnits" },
                name: "Organizational units",
                exact: true,
              },
            ];
          },
        },
      },
      {
        path: "groups",
        name: "Groups",
        component: () =>
          import(/* webpackChunkName: "GroupList" */ "../views/OrganizationAdmin/GroupList.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                name: "Groups",
                routeName: "Groups",
              },
            ];
          },
        },
      },
      {
        path: "groups/:id",
        name: "SingleGroup",
        component: () =>
          import(
            /* webpackChunkName: "GroupDetails" */ "../views/OrganizationAdmin/GroupDetails.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "Groups" },
                name: "Groups",
                exact: true,
              },
            ];
          },
        },
      },
      {
        path: "providers",
        name: "OrganizationProviders",
        component: () =>
          import(
            /* webpackChunkName: "ProviderList" */ "../views/OrganizationAdmin/ProviderList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                routeName: "OrganizationProviders",
                name: "Providers",
              },
            ];
          },
        },
      },
      {
        path: "providers/:id",
        name: "SingleProvider",
        component: () =>
          import(
            /* webpackChunkName: "ProviderDetails" */ "../views/OrganizationAdmin/ProviderDetails.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationProviders" },
                name: "Providers",
                exact: true,
              },
            ];
          },
        },
      },
      {
        path: "authorized-users",
        name: "OrganizationAuthUsers",
        component: () =>
          import(
            /* webpackChunkName: "AuthUserList" */ "../views/OrganizationAdmin/AuthUserList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                routeName: "OrganizationAuthUsers",
                name: "Authorized users",
              },
            ];
          },
        },
      },
      {
        path: "authorized-users/:id",
        name: "SingleAuthUser",
        component: () =>
          import(
            /* webpackChunkName: "AuthUserDetails" */ "../views/OrganizationAdmin/AuthUserDetails.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationAuthUsers" },
                name: "Authorized users",
                exact: true,
              },
            ];
          },
        },
      },
      {
        path: "action-links",
        name: "OrganizationActionLinks",
        component: () =>
          import(/* OrganizationActionLinks */ "../views/OrganizationAdmin/ActionLinks.vue"),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationActionLinks" },
                name: "Action links",
                exact: true,
              },
            ];
          },
        },
      },
      {
        path: "locations",
        name: "OrganizationLocations",
        component: () =>
          import(
            /* webpackChunkName: "LocationList" */ "../views/OrganizationAdmin/LocationList.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                routeName: "OrganizationLocations",
                name: "Location",
              },
            ];
          },
        },
      },
      {
        path: "analytics",
        name: "OrganizationAnalytics",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAnalytics" */ "../views/OrganizationAdmin/OrganizationAnalytics.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                routeName: "OrganizationAnalytics",
                name: "Analytics",
              },
            ];
          },
        },
      },
      {
        path: "analytics/sex",
        name: "OrganizationAnalyticsSex",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAnalyticsSex" */ "../views/OrganizationAdmin/OrganizationAnalyticsSex.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "OrganizationAnalyticsSex",
                name: "Sex",
              },
            ];
          },
        },
      },
      {
        path: "analytics/age",
        name: "OrganizationAnalyticsAge",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAnalyticsAge" */ "../views/OrganizationAdmin/OrganizationAnalyticsAge.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "OrganizationAnalyticsAge",
                name: "Age",
              },
            ];
          },
        },
      },
      {
        path: "analytics/city",
        name: "OrganizationAnalyticsCity",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAnalyticsCity" */ "../views/OrganizationAdmin/OrganizationAnalyticsCity.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "OrganizationAnalyticsCity",
                name: "City",
              },
            ];
          },
        },
      },
      {
        path: "analytics/keyword",
        name: "OrganizationAnalyticsKeyword",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAnalyticsKeyword" */ "../views/OrganizationAdmin/OrganizationAnalyticsKeyword.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                to: { name: "OrganizationAnalytics" },
                name: "Analytics",
                exact: true,
              },
              {
                routeName: "OrganizationAnalyticsKeyword",
                name: "Keyword",
              },
            ];
          },
        },
      },
      {
        path: "organization-settings",
        name: "OrganizationSettings",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAnalytics" */ "../views/OrganizationAdmin/OrganizationSettingsPage.vue"
          ),
        meta: {
          breadCrumb() {
            return [
              {
                name: "Organization",
                to: { name: "OrganizationAdmin" },
                exact: true,
              },
              {
                routeName: "OrganizationSettings",
                name: "Settings",
              },
            ];
          },
        },
      },
    ],
  },
];
