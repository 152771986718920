<template>
  <div class="magic-link-page">
    <div class="card">
      <h1 class="card-title">Now check your inbox</h1>

      <button class="close-btn" @click="cancel">
        <v-icon size="20" color="var(--color-grey-primary)">{{ mdiClose }}</v-icon>
      </button>

      <img src="/img/pg-login-greeting.svg" class="pg-img" alt="" />

      <div class="text-content">
        <p>
          We emailed a magic link to <span class="email">{{ userEmail }}</span>
        </p>
        <p>No email? Check your spam folder or try resending.</p>
      </div>

      <FormErrorMessage :error-message="errorMessage" />

      <AppButton :loading="loading" class="submit-btn" @click="resendLink">
        Resend E-mail
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";
import { mdiClose } from "@mdi/js";
import FormErrorMessage from "@/components/FormErrorMessage.vue";

export default Vue.extend({
  name: "MagicLinkPage",
  components: {
    FormErrorMessage,
  },
  data() {
    return {
      mdiClose,
      loading: false,
      errorMessage: "",
      userEmail: this.$route.query.email,
    };
  },
  methods: {
    async resendLink() {
      if (!this.userEmail) {
        this.errorMessage = "Email is not provided";
        return;
      }

      this.loading = true;
      const { error } = await api.user.magicLink({ email: this.userEmail });
      this.loading = false;

      if (error) {
        this.errorMessage = error.text;
        return;
      }
    },
    cancel() {
      this.$router.push({ name: "Login" });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.magic-link-page {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 0;

  @include breakpoint(xsmall) {
    margin: 0 -20px;
    padding: 0;
  }

  .card {
    @include sign-card;

    .card-title {
      max-width: 290px;
      margin-bottom: 24px;
    }

    .text-content {
      max-width: 310px;
      margin-bottom: 44px;
      line-height: 20px;

      .email {
        color: var(--color-purple-primary);
        font-weight: var(--font-weight-bolder);
      }
    }

    .submit-btn {
      width: 100%;
      color: var(--color-white);
    }
  }
}
</style>
