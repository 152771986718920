<template>
  <GmapAutocomplete
    :options="searchOptions"
    select-first-on-enter
    class="app-location-input"
    @place_changed="setLocation"
  >
    <template v-slot:default="slotProps">
      <v-text-field
        ref="input"
        v-model="searchKeyword"
        height="56"
        :label="label"
        :rules="rules"
        hide-details="auto"
        :appendIcon="mdiMagnify"
        outlined
        class="input"
        @attrs="slotProps.attrs"
      />
    </template>
  </GmapAutocomplete>
</template>

<script lang="ts">
import Vue from "vue";
import ILocationResponse from "@/types/ILocationResponse";
import { mdiMagnify } from "@mdi/js";

export default Vue.extend({
  name: "AppLocationInput",
  props: {
    value: {
      type: String,
      required: true,
    },
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "Enter location",
    },
    rules: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      mdiMagnify,
      searchKeyword: this.value,
      searchOptions: {
        strictBounds: true,
        componentRestrictions: {
          country: "us",
        },
      },
    };
  },
  watch: {
    value(value) {
      this.searchKeyword = value;
    },
  },
  methods: {
    setLocation(location: ILocationResponse) {
      if (!location.formatted_address || !location.geometry) return;

      this.$emit("update:value", location.formatted_address);
      this.$emit("update:latitude", location.geometry.location.lat());
      this.$emit("update:longitude", location.geometry.location.lng());
    },
  },
});
</script>

<style lang="scss" scoped>
.app-location-input {
  .input {
    border-radius: 8px;

    &::v-deep input::placeholder {
      opacity: 0%;
    }
  }
}
</style>
