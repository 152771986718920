<template>
  <button class="app-add-button" type="button" @click="$emit('click', $event)">
    <v-icon size="14" color="var(--color-blue-primary)">{{ mdiPlus }}</v-icon>
    <slot />
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import { mdiPlus } from "@mdi/js";

export default Vue.extend({
  name: "AppButtonAdd",
  data() {
    return {
      mdiPlus,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-add-button {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-blue-primary);
  font-weight: var(--font-weight-bolder);
}
</style>
