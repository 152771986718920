import Vue from "vue";

import AppRowField from "@/components/AppComponents/AppRowField.vue";
import AppInfoDialog from "@/components/AppComponents/AppInfoDialog.vue";
import AppButtonAdd from "@/components/AppComponents/AppButtonAdd.vue";
import AppReadOnlyNotice from "@/components/AppComponents/AppReadOnlyNotice.vue";
import AppSubscriptionOnlyNotice from "@/components/AppComponents/AppSubscriptionOnlyNotice.vue";
import AppInput from "@/components/AppComponents/AppInput.vue";
import AppActionMenu from "@/components/AppComponents/AppActionMenu.vue";
import AppDialog from "@/components/AppComponents/AppDialog.vue";
import AppLocationInput from "@/components/AppComponents/AppLocationInput.vue";
import AppButton from "@/components/AppComponents/AppButton.vue";
import AppAutocomplete from "@/components/AppComponents/AppAutocomplete.vue";
import AppCombobox from "@/components/AppComponents/AppCombobox.vue";
import AppTextarea from "@/components/AppComponents/AppTextarea.vue";
import AppSwitch from "@/components/AppComponents/AppSwitch.vue";
import AppSelect from "@/components/AppComponents/AppSelect.vue";
import AppConfirmationDialog from "@/components/AppComponents/AppConfirmationDialog.vue";
import AppButtonShowMore from "@/components/AppComponents/AppButtonShowMore.vue";
import AppBreadcrumbs from "@/components/AppComponents/AppBreadcrumbs.vue";

Vue.component("AppRowField", AppRowField);
Vue.component("AppInfoDialog", AppInfoDialog);
Vue.component("AppButtonAdd", AppButtonAdd);
Vue.component("AppReadOnlyNotice", AppReadOnlyNotice);
Vue.component("AppSubscriptionOnlyNotice", AppSubscriptionOnlyNotice);
Vue.component("AppInput", AppInput);
Vue.component("AppActionMenu", AppActionMenu);
Vue.component("AppDialog", AppDialog);
Vue.component("AppLocationInput", AppLocationInput);
Vue.component("AppButton", AppButton);
Vue.component("AppAutocomplete", AppAutocomplete);
Vue.component("AppCombobox", AppCombobox);
Vue.component("AppTextarea", AppTextarea);
Vue.component("AppSwitch", AppSwitch);
Vue.component("AppSelect", AppSelect);
Vue.component("AppConfirmationDialog", AppConfirmationDialog);
Vue.component("AppButtonShowMore", AppButtonShowMore);
Vue.component("AppBreadcrumbs", AppBreadcrumbs);
