<template>
  <AppInfoDialog :is-info-dialog-open.sync="isOpen" @close="isOpen = false">
    <template #activator>
      <button @click="isOpen = true" class="icon-btn">i</button>
    </template>
    <template #title> <slot name="title" /> </template>
    <template #text>
      This information cannot be edited here. We receive this information from NPPES database. If
      you believe there is an error, please contact them directly to update:
      <a href="https://nppes.cms.hhs.gov" target="_blank">nppes.cms.hhs.gov</a>
    </template>
  </AppInfoDialog>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppReadOnlyNotice",
  data() {
    return {
      isOpen: false,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  border: 2px solid var(--color-purple-light);
  border-radius: 50%;
}
</style>
