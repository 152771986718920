import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IInsurance } from "@/types";

export const getInsurances = async (npi: string): Promise<IFetchResponse<IInsurance[]>> => {
  return await callProfileApi("get", `/provider/${npi}/insurances`);
};

export const updateInsuranceBrand = async (
  npi: string,
  brandId: string
): Promise<IFetchResponse<IInsurance>> => {
  return await callProfileApi("post", `/provider/${npi}/insurances/${brandId}/brand`);
};

export const updateInsurancePlan = async (
  npi: string,
  planId: string
): Promise<IFetchResponse<boolean>> => {
  return await callProfileApi("post", `/provider/${npi}/insurances/${planId}/plan`);
};
