import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IUser } from "@/types";

const profileUrl = "/user";

export const get = async (organizationId: string): Promise<IFetchResponse<IUser>> => {
  return await callProfileApi("get", `${profileUrl}`, {
    params: { organizationId: organizationId },
  });
};

export const magicLink = async (email: object): Promise<IFetchResponse<number>> => {
  return await callProfileApi("post", "/magic-link", email);
};

export const verificationLink = async (): Promise<IFetchResponse<string>> => {
  return await callProfileApi("post", "/verification-link");
};

export const register = async (): Promise<IFetchResponse<string>> => {
  return await callProfileApi("post", "/register");
};
