import { Commit } from "vuex";
import { IOrganization } from "@/types";
import { IOrganizationUnit } from "@/types/IOrganization";

export interface OrganizationState {
  organization: null | IOrganization;
  orgUnits: IOrganizationUnit[];
  orgBreadcrumbs: string[];
}

export default {
  namespaced: true,
  state: (): OrganizationState => ({
    organization: null,
    orgUnits: [],
    orgBreadcrumbs: [],
  }),
  getters: {
    getOrganization: (state: OrganizationState) => state.organization,
    getOrgUnits: (state: OrganizationState) => state.orgUnits,
    getOrgBreadcrumbs: (state: OrganizationState) => state.orgBreadcrumbs,
  },
  mutations: {
    SET_ORGANIZATION: (state: OrganizationState, organization: IOrganization) => {
      state.organization = organization;
    },
    SET_ORG_UNITS: (state: OrganizationState, orgUnits: IOrganizationUnit[]) => {
      state.orgUnits = orgUnits;
    },
    SET_ORG_BREADCRUMBS: (state: OrganizationState, breadcrumbs: string[]) => {
      state.orgBreadcrumbs = breadcrumbs;
    },
  },
  actions: {
    setOrganization: ({ commit }: { commit: Commit }, organization: IOrganization) => {
      commit("SET_ORGANIZATION", organization);
    },
    setOrgUnits: ({ commit }: { commit: Commit }, orgUnits: IOrganizationUnit[]) => {
      commit("SET_ORG_UNITS", orgUnits);
    },
    setOrgBreadcrumbs: ({ commit }: { commit: Commit }, breadcrumbs: string[]) => {
      commit("SET_ORG_BREADCRUMBS", breadcrumbs);
    },
  },
};
