<template>
  <header class="header">
    <div class="container">
      <div v-if="getOrganization" class="org-info-wrapper">
        <img
          v-if="getOrganization.image"
          :src="MediaActions.getImageById(getOrganization.image, '80', '80')"
          width="40"
          height="40"
          class="org-logo"
          alt="logo"
        />

        <h1 class="org-title">{{ getOrganization.name }}</h1>
      </div>
      <a v-else :href="searchWebsiteURL" target="_blank">
        <img class="logo" src="/img/logo-beta.svg" alt="patient genie" />
      </a>

      <HeaderUserMenu />
    </div>
  </header>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { MediaActions } from "@/services/images";
import HeaderUserMenu from "@/components/HeaderUserMenu.vue";

export default Vue.extend({
  name: "TheHeader",
  components: {
    HeaderUserMenu,
  },
  data() {
    return {
      MediaActions,
      searchWebsiteURL: process.env.VUE_APP_SEARCH_URL,
    };
  },
  computed: {
    ...mapGetters("organization", ["getOrganization"]),
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.header {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: var(--color-white);

  .container {
    @include container-large;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .org-info-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;

    .org-logo {
      width: 40px;
      height: 40px;
      border: 1px solid var(--color-grey-secondary);
      border-radius: 8px;
    }

    .org-title {
      font-size: var(--font-big);
    }
  }

  .logo {
    max-height: 34px;
  }
}
</style>
