import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import { IOrganizationProvider, IOrganizationUnit } from "@/types/IOrganization";
import IOrganizationGroup from "@/types/IOrganizationGroup";

export const getProviderInfo = async (
  orgId: string,
  npi: string
): Promise<IFetchResponse<IOrganizationProvider>> => {
  return await callProfileApi("get", `/organizations/${orgId}/providers/${npi}`);
};

export const getProviderGroups = async (
  orgId: string,
  npi: string
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroup[] }>> => {
  return await callProfileApi("get", `/organizations/${orgId}/providers/${npi}/groups`);
};

export const getProviderUnits = async (
  orgId: string,
  npi: string
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationUnit[] }>> => {
  return await callProfileApi("get", `/organizations/${orgId}/providers/${npi}/units`);
};
