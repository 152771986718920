<template>
  <v-switch
    v-model="switchValue"
    inset
    dense
    hide-details
    :ripple="false"
    :color="color"
    class="app-switch"
    @change="toggleSwitch"
  />
</template>

<script>
export default {
  name: "AppSwitch",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "var(--color-purple-primary)",
    },
  },
  data() {
    return {
      switchValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.switchValue = val;
    },
  },
  methods: {
    toggleSwitch() {
      this.$emit("update:value", this.switchValue);
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-switch::v-deep {
  width: 38px;
  height: 22px;

  .v-input--switch__track {
    opacity: 100% !important;
  }

  .v-input--switch__thumb {
    color: var(--color-white) !important;
  }

  &.v-input--selection-controls {
    margin: 0 0 0 4px;
    padding: 0;
  }

  &.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {
    width: 38px !important;
    height: 22px;
  }

  &.v-input--switch--inset .v-input--selection-controls__ripple,
  &.v-input--switch--inset .v-input--switch__thumb {
    transform: translate(0, 1px) !important;
  }

  &.v-input--switch.v-input--dense .v-input--switch__thumb {
    width: 16px;
    height: 16px;
  }

  &.v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple,
  &.v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(15px, 1px) !important;
  }
}
</style>
