import { callProfileApi, callSearchApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import {
  IAnalyticsDefaultSeries,
  IAnalyticsSearches,
  IAnalyticsSearchesFilters,
  IAnalyticsSearchesParams,
  IAnalyticsSexes,
} from "@/types/IAnalytics";

const ANALYTICS_HANDLER = "/analytics";

export const getOrgAnalyticsFilterValues = async (
  orgId: string,
  { unit, group, provider }: { unit: string; group: string; provider: string }
) => {
  const params = {
    u: unit,
    g: group,
    p: provider,
  };

  return await callProfileApi("get", `/organizations/${orgId}/filter-values/analytics`, { params });
};

export const getSearchesAnalyticsFiltersValues = async (
  period = "month",
  idsList: string[]
): Promise<IFetchResponse<IAnalyticsSearchesFilters>> => {
  return await callSearchApi("post", `${ANALYTICS_HANDLER}/filters-values`, idsList, {
    params: {
      period: period,
    },
  });
};

export const getSearchesAnalytics = async (
  idsList: string[],
  { period, keyword, searchPath, city, sex }: IAnalyticsSearchesParams
): Promise<IFetchResponse<IAnalyticsSearches[]>> => {
  const params = {
    period: period || "month",
    k: keyword,
    sp: searchPath,
    c: city,
    s: sex,
  };

  return await callSearchApi("post", `${ANALYTICS_HANDLER}/searches`, idsList, {
    params,
  });
};

export const getSexesAnalytics = async (
  period = "month",
  idsList: string[]
): Promise<IFetchResponse<IAnalyticsSexes>> => {
  return await callSearchApi("post", `${ANALYTICS_HANDLER}/sexes`, idsList, {
    params: {
      period: period,
    },
  });
};

export const getAgesAnalytics = async (
  period = "month",
  idsList: string[]
): Promise<IFetchResponse<IAnalyticsDefaultSeries[]>> => {
  return await callSearchApi("post", `${ANALYTICS_HANDLER}/ages`, idsList, {
    params: {
      period: period,
    },
  });
};

export const getKeywordsAnalytics = async (
  period = "month",
  idsList: string[]
): Promise<IFetchResponse<IAnalyticsDefaultSeries[]>> => {
  return await callSearchApi("post", `${ANALYTICS_HANDLER}/keywords`, idsList, {
    params: {
      period: period,
    },
  });
};

export const getCitiesAnalytics = async (
  period = "month",
  idsList: string[]
): Promise<IFetchResponse<IAnalyticsDefaultSeries[]>> => {
  return await callSearchApi("post", `${ANALYTICS_HANDLER}/cities`, idsList, {
    params: {
      period: period,
    },
  });
};
