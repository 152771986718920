<template>
  <v-dialog v-model="isDialogOpen" max-width="443" content-class="app-confirmation-dialog">
    <h2 class="dialog-title">
      <slot name="title" />
    </h2>
    <div class="actions">
      <AppButton width="144px" outlined @click="confirm">Yes</AppButton>
      <AppButton width="144px" outlined color="var(--color-blue-primary)" @click="cancel">
        No
      </AppButton>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppConfirmationDialog",
  props: {
    isConfirmationDialogOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isDialogOpen: {
      get(): boolean {
        return this.isConfirmationDialogOpen;
      },
      set(value: boolean) {
        this.$emit("update:isConfirmationDialogOpen", value);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.isDialogOpen = false;
    },
    cancel() {
      this.$emit("cancel");
      this.isDialogOpen = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep .app-confirmation-dialog {
  padding: 24px;
  background-color: var(--color-white);
  border-radius: var(--default-border-radius);

  .dialog-title {
    margin-bottom: 44px;
    font-size: var(--font-big);
    text-align: center;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}
</style>
