import { IFetchResponse, IPaginationRequest } from "@/types";
import {
  IActionLink,
  IActionLinkAutocompleteResult,
  IActionLinkChip,
  IActionLinkValues,
} from "@/types/IActionLinks";
import { callProfileApi, callSearchApi } from "@/api/apiHelper";

export const getActionLinks = async (
  orgId: string,
  { pageNumber, pageSize, tab, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IActionLinkChip[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    tab,
    k: keyword,
  };

  return await callProfileApi("get", `/organizations/${orgId}/action-links`, { params });
};

export const createActionLink = async (
  orgId: string,
  actionLink: IActionLink
): Promise<IFetchResponse<{ totalAmount: number; items: IActionLinkChip[] }>> => {
  return await callProfileApi("post", `/organizations/${orgId}/action-links`, actionLink, {
    params: { tab: "active" },
  });
};

export const getActionLink = async (
  orgId: string,
  linkId: string
): Promise<IFetchResponse<IActionLink>> => {
  return await callProfileApi("get", `/organizations/${orgId}/action-links/${linkId}`);
};

export const getLinkValues = async (orgId: string): Promise<IFetchResponse<IActionLinkValues>> => {
  return await callProfileApi("get", `/organizations/${orgId}/action-links/values`);
};

export const updateActionLink = async (
  orgId: string,
  linkId: string,
  actionLink: IActionLink
): Promise<IFetchResponse<{ totalAmount: number; items: IActionLinkChip[] }>> => {
  return await callProfileApi("put", `/organizations/${orgId}/action-links/${linkId}`, actionLink, {
    params: { tab: "active" },
  });
};

export const updateActionLinkStatus = async (
  orgId: string,
  status: string,
  idsList: string[],
  tab: string
): Promise<IFetchResponse<{ totalAmount: number; items: IActionLinkChip[] }>> => {
  return await callProfileApi(
    "put",
    `/organizations/${orgId}/action-links/status/${status}`,
    {
      ids: idsList,
    },
    {
      params: { tab: tab },
    }
  );
};

export const getAutocompleteResults = async (
  keyword: string
): Promise<IFetchResponse<IActionLinkAutocompleteResult[]>> => {
  return await callSearchApi("get", `/autocomplete`, {
    params: { keyword: keyword },
  });
};

export const getAutocompleteSymptomsResults = async (
  keyword: string
): Promise<IFetchResponse<IActionLinkAutocompleteResult[]>> => {
  return await callSearchApi("get", `/autocomplete-symptom`, {
    params: { keyword: keyword },
  });
};
