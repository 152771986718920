const validationRules = {
  data() {
    return {
      rules: {
        required: (value: string | [] | object) => {
          const isEmptyArray = Array.isArray(value) && !value.length;
          const isEmptyObject =
            typeof value === "object" &&
            value !== null &&
            Object.values(value).every((val) => !val);

          if (isEmptyArray || isEmptyObject) return "Required";

          return !!value || "Required";
        },
        email: (value: string) => {
          const emailPattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailPattern.test(value) || "Invalid e-mail";
        },
        url: (value: string) => {
          let url;

          try {
            url = new URL(value);
          } catch (_) {
            return "URL should be in the format https://yourwebsite.com";
          }

          return url.protocol === "https:" || "URL should be in the format https://yourwebsite.com";
        },
      },
    };
  },
};

export default validationRules;
