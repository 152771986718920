import { callProfileApi } from "@/api/apiHelper";
import {
  IFetchResponse,
  IFocusArea,
  IOrganizationGroup,
  IPaginationRequest,
  IProviderDisease,
  IProviderProcedure,
} from "@/types";
import {
  IGroupProvidersFiltersRequest,
  IGroupProviderFilters,
  IGroupSexes,
  IOrganizationGroupProvider,
  IOrganizationGroupUser,
} from "@/types/IOrganizationGroup";
import { IProviderProcedureChip } from "@/types/IProvider";

export const getGroupInfo = async (id: string): Promise<IFetchResponse<IOrganizationGroup>> => {
  return await callProfileApi("get", `/groups/${id}`);
};

export const getGroupProviders = async (
  id: string,
  {
    pageNumber,
    pageSize,
    keyword,
    focusArea,
    panelDemographics,
    disease,
    sex,
    procedure,
  }: IGroupProvidersFiltersRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroupProvider[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
    fa: focusArea,
    pd: panelDemographics,
    di: disease,
    se: sex,
    pr: procedure,
  };

  return await callProfileApi("get", `/groups/${id}/providers`, { params });
};

export const addProviderToGroup = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroupProvider[] }>> => {
  return await callProfileApi("post", `/groups/${id}/providers`, { data: { ids: idsList } });
};

export const deleteProvidersFromGroup = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroupProvider[] }>> => {
  return await callProfileApi("delete", `/groups/${id}/providers`, { data: { ids: idsList } });
};

export const getGroupUsers = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroupUser[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/groups/${id}/users`, { params });
};

export const updateGroup = async (
  id: string,
  group: IOrganizationGroup
): Promise<IFetchResponse<IOrganizationGroup[]>> => {
  return await callProfileApi("put", `/groups/${id}`, group);
};

export const addProvidersToGroup = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroupProvider[] }>> => {
  return await callProfileApi("post", `/groups/${id}/providers`, { ids: idsList });
};

export const removeProvidersFromGroup = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganizationGroupProvider[] }>> => {
  return await callProfileApi("delete", `/groups/${id}/providers`, { data: { ids: idsList } });
};

export const updatePanelDemographics = async (
  id: string,
  demographics: {
    sexes: IGroupSexes;
    ages: string[];
  }
): Promise<IFetchResponse> => {
  return await callProfileApi("patch", `/groups/${id}/panel-demographics`, demographics);
};

export const getGroupProcedures = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderProcedureChip[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/groups/${id}/procedures`, { params });
};

export const getGroupProcedure = async (
  id: string,
  procedureId: string
): Promise<IFetchResponse<IProviderProcedure>> => {
  return await callProfileApi("get", `/groups/${id}/procedures/${procedureId}}`);
};

export const addGroupProcedure = async (
  id: string,
  procedure: IProviderProcedure
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderProcedureChip[] }>> => {
  return await callProfileApi("post", `/groups/${id}/procedures`, procedure);
};

export const updateGroupProcedure = async (
  id: string,
  procedureId: string,
  procedure: IProviderProcedure
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderProcedureChip[] }>> => {
  return await callProfileApi("post", `/groups/${id}/procedures`, procedure);
};

export const removeGroupProcedures = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderProcedureChip[] }>> => {
  return await callProfileApi("delete", `/groups/${id}/procedures`, { data: { ids: idsList } });
};

export const getGroupDiseases = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderDisease[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/groups/${id}/diseases`, { params });
};

export const getGroupDisease = async (
  id: string,
  diseaseId: string
): Promise<IFetchResponse<IProviderDisease>> => {
  return await callProfileApi("get", `/groups/${id}/diseases/${diseaseId}}`);
};

export const addGroupDisease = async (
  id: string,
  disease: IProviderDisease
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderDisease[] }>> => {
  return await callProfileApi("post", `/groups/${id}/diseases`, disease);
};

export const updateGroupDisease = async (
  id: string,
  diseaseId: string,
  disease: IProviderDisease
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderDisease[] }>> => {
  return await callProfileApi("post", `/groups/${id}/diseases`, disease);
};

export const removeGroupDiseases = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IProviderDisease[] }>> => {
  return await callProfileApi("delete", `/groups/${id}/diseases`, { data: { ids: idsList } });
};

export const getGroupFocusAreas = async (
  id: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IFocusArea[] }>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/groups/${id}/focus-areas`, { params });
};

export const addGroupFocusArea = async (
  id: string,
  focusArea: IFocusArea
): Promise<IFetchResponse<{ totalAmount: number; items: IFocusArea[] }>> => {
  return await callProfileApi("post", `/groups/${id}/focus-areas`, focusArea);
};

export const removeGroupFocusAreas = async (
  id: string,
  idsList: string[]
): Promise<IFetchResponse<{ totalAmount: number; items: IFocusArea[] }>> => {
  return await callProfileApi("delete", `/groups/${id}/focus-areas`, { data: { ids: idsList } });
};

export const getGroupTaxonomy = async (id: string): Promise<IFetchResponse<string[]>> => {
  return await callProfileApi("get", `/groups/${id}/taxonomies`);
};

export const getGroupProvidersFilters = async (
  id: string
): Promise<IFetchResponse<IGroupProviderFilters>> => {
  return await callProfileApi("get", `/groups/${id}/filters-values`);
};
