import { imageApi } from "../plugins/axios";

const BASE_URL = process.env.VUE_APP_IMG_API_URL;

interface defaultImg {
  location: string;
}

const defaultImg: defaultImg = {
  location: "/img/img-placeholder.png",
};

class MediaActions {
  static async saveImage(file: File) {
    if (file.size > 10000000) {
      return Promise.reject(new Error("Use an image smaller than 10Mb"));
    }
    const formData = new FormData();
    formData.append("file", file);

    const { data } = await imageApi.post("/", formData);

    return data;
  }

  static getImageById(id: string, width: string, height: string, type = "avatar") {
    if (!id) return defaultImg[type as keyof defaultImg];
    let imageUrl = `${BASE_URL}/${id}`;
    if (width) imageUrl += `/${width}`;
    if (width && height) imageUrl += `/${height}`;
    return imageUrl;
  }
}

export { MediaActions, defaultImg };
