import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IProviderLocation, IPaginationRequest } from "@/types";
import { ILocationFull, ILocationShort } from "@/types/ILocation";

export const getLocations = async (
  npi: string,
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<ILocationShort[]>> => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  };

  return await callProfileApi("get", `/provider/${npi}/locations`, { params });
};

export const getLocation = async (
  npi: string,
  locationId: string
): Promise<IFetchResponse<ILocationFull>> => {
  return await callProfileApi("get", `/provider/${npi}/locations/${locationId}`);
};

export const addLocation = async (
  npi: string,
  location: IProviderLocation
): Promise<IFetchResponse<ILocationShort[]>> => {
  return await callProfileApi("post", `/provider/${npi}/locations`, location);
};

export const updateLocation = async (
  npi: string,
  locationId: string,
  location: IProviderLocation
): Promise<IFetchResponse<ILocationShort[]>> => {
  return await callProfileApi("put", `/provider/${npi}/locations/${locationId}`, location);
};

export const deleteLocation = async (
  npi: string,
  locationId: string
): Promise<IFetchResponse<ILocationShort[]>> => {
  return await callProfileApi("delete", `/provider/${npi}/locations/${locationId}`);
};

export const verifyLocation = async (
  npi: string,
  locationId: string
): Promise<IFetchResponse<ILocationShort[]>> => {
  return await callProfileApi("post", `/provider/${npi}/locations/${locationId}/verify`);
};
