import Vue from "vue";
import "@/plugins/firebase";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/components";
import GmapVue from "gmap-vue";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

Vue.config.productionTip = false;

Vue.use(Donut);

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_API_KEY,
    libraries: "places",
    language: "en",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
