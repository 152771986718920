<template>
  <div class="app-action-menu">
    <v-menu v-model="isOpen" transition="slide-y-transition" bottom left content-class="menu">
      <template #activator="{}">
        <button class="icon-btn" @click="isOpen = true">
          <v-icon size="16">{{ mdiDotsVertical }}</v-icon>
        </button>
      </template>

      <button v-for="(item, i) in items" :key="i" class="menu-item-btn" @click="handleClick(item)">
        <span class="item-title">{{ item.title }}</span>
        <v-icon class="item-icon"> {{ item.icon }}</v-icon>
      </button>
    </v-menu>

    <AppInfoDialog
      :is-info-dialog-open.sync="modalSettings.isOpen"
      @close="modalSettings.isOpen = false"
    >
      <template #text>
        <div v-html="modalSettings.reason"></div>
      </template>
    </AppInfoDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mdiDotsVertical } from "@mdi/js";

export default Vue.extend({
  name: "AppActionMenu",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mdiDotsVertical,
      modalSettings: {
        isOpen: false,
        reason: "",
      },
      isOpen: false,
    };
  },
  methods: {
    handleClick(item: { action: string; disabled: boolean; reason: string }) {
      if (item.disabled) {
        this.modalSettings.isOpen = true;
        this.modalSettings.reason = item.reason;
        return;
      }

      this.$emit("click", item.action);
    },
  },
});
</script>

<style lang="scss" scoped>
.v-menu__content::v-deep.menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  padding: 16px;
  border-radius: 16px;
  box-shadow: var(--default-box-shadow);

  .item-title {
    font-weight: var(--font-weight-bolder);
  }

  .menu-item-btn {
    display: grid;
    grid-template-columns: auto 24px;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
    transition: color 0.2s ease-in-out;

    .item-icon {
      color: var(--color-grey-primary);
      transition: color 0.2s ease-in-out;
    }

    &:hover {
      color: var(--color-purple-primary);

      .item-icon {
        color: var(--color-purple-primary);
      }
    }
  }
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-purple-light);
  border-radius: 50%;
}
</style>
