import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LoginPage from "@/views/LoginPage.vue";
import ClaimProfilePage from "@/views/ClaimProfilePage.vue";
import SignupPage from "@/views/SignupPage.vue";
import MagicLinkPage from "@/views/MagicLinkPage.vue";
import EmailConfirmationPage from "@/views/EmailConfirmationPage.vue";
import profile from "@/router/profile";
import organization from "@/router/organization";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...profile,
  ...organization,
  {
    path: "/redirect",
    name: "Home",
    component: () => import(/* webpackChunkName: "HomePage" */ "../views/HomePage.vue"),
  },
  {
    path: "/telehealth",
    name: "Telehealth",
    component: () => import(/* webpackChunkName: "TelehealthPage" */ "../views/TelehealthPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/secure-messaging",
    name: "SecureMessaging",
    component: () =>
      import(/* webpackChunkName: "SecureMessagingPage" */ "../views/SecureMessagingPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "SettingsPage" */ "../views/Settings/SettingsPage.vue"),
    meta: { requiresAuth: true },
    redirect: { name: "UserManagement" },
    children: [
      {
        path: "user-management",
        name: "UserManagement",
        component: () =>
          import(
            /* webpackChunkName: "SettingsUserManagement" */ "../views/Settings/SettingsUserManagement.vue"
          ),
      },
    ],
  },
  {
    path: "/claim-profile",
    name: "ClaimProfile",
    component: ClaimProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { hideForAuth: true },
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupPage,
    meta: { hideForAuth: true },
  },
  {
    path: "/magic-link",
    name: "MagicLink",
    component: MagicLinkPage,
    meta: { hideForAuth: true },
  },
  {
    path: "/need-confirmation",
    name: "EmailConfirmation",
    component: EmailConfirmationPage,
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.query.organizationId && !to.query.organizationId) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    next({
      ...to,
      query: {
        ...to.query,
        organizationId: from.query.organizationId,
      },
    });
  }

  next();
});

export default router;
