import ax from "axios";
import { getAuth, getIdToken } from "firebase/auth";

const profileApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

profileApi.interceptors.request.use(
  async (config) => {
    const currentUser = getAuth().currentUser;
    if (!currentUser || !config.headers) return config;

    config.headers["X-Auth-Token"] = await getIdToken(currentUser);
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

profileApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);

    // TODO add error notification component from web project
    // store.dispatch("setErrorNotification", { text: error.response.data });
  }
);

// Search API
const partnerKey = process.env.VUE_APP_PARTNER_KEY;

const searchApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_SEARCH_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

searchApi.interceptors.request.use(
  async (config) => {
    if (!config.params) config.params = {};

    if (partnerKey) {
      config.params.key = partnerKey;
    }

    const currentUser = getAuth().currentUser;
    if (!currentUser || !config.headers) return config;

    config.headers["X-Auth-Token"] = await getIdToken(currentUser);
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

searchApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

// Images  API
const imageApi = ax.create({
  baseURL: `${process.env.VUE_APP_IMG_API_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

imageApi.interceptors.request.use(
  async (config) => {
    const currentUser = getAuth().currentUser;
    if (!currentUser || !config.headers) return config;

    config.headers["X-Auth-Token"] = await getIdToken(currentUser);
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

imageApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export { profileApi, searchApi, imageApi };
