<template>
  <v-textarea
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    hide-details="auto"
    outlined
    class="app-textarea"
  />
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppTextarea",
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    rules: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(value) {
      this.$emit("update:value", value);
    },
  },
});
</script>

<style lang="scss" scoped>
.app-textarea {
  border-radius: 8px;
}
</style>
