import { Commit } from "vuex";
import { IProvider } from "@/types";
import { IProviderSexes } from "@/types/IProvider";

export interface ProviderState {
  provider: null | IProvider;
}

export default {
  namespaced: true,
  state: (): ProviderState => ({
    provider: null,
  }),
  getters: {
    getProvider: (state: ProviderState) => state.provider,
    getProfileProgressValue: (state: ProviderState) => state.provider?.percentage,
  },
  mutations: {
    SET_PROVIDER: (state: ProviderState, provider: IProvider) => {
      state.provider = provider;
    },
    SET_PROVIDER_PROFILES: (state: ProviderState, profiles: string[]) => {
      if (state.provider) state.provider.profiles = profiles;
    },
    SET_PROVIDER_LANGUAGES: (state: ProviderState, languages: string[]) => {
      if (state.provider) state.provider.languages = languages;
    },
    SET_PROVIDER_AGES: (state: ProviderState, ages: string[]) => {
      if (state.provider) state.provider.ages = ages;
    },
    SET_PROVIDER_SEXES: (state: ProviderState, sexes: IProviderSexes) => {
      if (state.provider) state.provider.sexes = sexes;
    },
    SET_PROVIDER_AVATAR: (state: ProviderState, avatar: string) => {
      if (state.provider) state.provider.avatar = avatar;
    },
  },
  actions: {
    setProvider: ({ commit }: { commit: Commit }, provider: IProvider) => {
      commit("SET_PROVIDER", provider);
    },
    setProviderProfiles: ({ commit }: { commit: Commit }, profiles: string[]) => {
      commit("SET_PROVIDER_PROFILES", profiles);
    },
    setProviderLanguages: ({ commit }: { commit: Commit }, languages: string[]) => {
      commit("SET_PROVIDER_LANGUAGES", languages);
    },
    setProviderAges: ({ commit }: { commit: Commit }, ages: string[]) => {
      commit("SET_PROVIDER_AGES", ages);
    },
    setProviderSexes: ({ commit }: { commit: Commit }, sexes: IProviderSexes) => {
      commit("SET_PROVIDER_SEXES", sexes);
    },
    setProviderAvatar: ({ commit }: { commit: Commit }, avatar: string) => {
      commit("SET_PROVIDER_AVATAR", avatar);
    },
  },
};
