<template>
  <div class="login-page">
    <div class="card">
      <h1 class="card-title">Log in</h1>

      <button class="google-btn" @click="googleLogin">
        <img src="/img/google-logo.svg" alt="" />
        Log in with Google
      </button>

      <img src="/img/pg-login-greeting.svg" class="pg-img" alt="" />

      <div class="card-divider">
        <span class="divider-text">Log in with E-mail</span>
      </div>

      <v-form v-model="isFormValid" class="card-form" @submit.prevent>
        <AppInput
          :value.sync="loginData.email"
          :rules="[rules.required, rules.email]"
          label="E-mail"
          type="email"
          @pressEnter="!isPasswordOn ? loginWithMagicLink() : {}"
        />

        <AppInput
          v-if="isPasswordOn"
          :value.sync="loginData.password"
          :rules="[rules.required]"
          :type="isShowPassword ? 'text' : 'password'"
          :append-icon="isShowPassword ? mdiEye : mdiEyeOff"
          label="Password"
          @click:append="isShowPassword = !isShowPassword"
          @pressEnter="login"
        />

        <FormErrorMessage :error-message="errorMessage" />

        <div class="login-type-switch">
          <span>You don't have access to email, or would like to enter password instead</span>
          <v-switch v-model="isPasswordOn" inset dense hide-details />
        </div>

        <AppButton
          v-if="isPasswordOn"
          :loading="loading"
          :disabled="!isFormValid"
          class="submit-btn"
          @click="login"
        >
          Log in
        </AppButton>
        <AppButton
          v-else
          :loading="loading"
          :disabled="!isFormValid"
          class="submit-btn"
          @click="loginWithMagicLink"
        >
          Log in with E-mail
        </AppButton>
      </v-form>

      <div class="card-footer">
        <span class="redirect-text">
          Don't have a profile? <router-link to="/signup">Sign up here</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { FirebaseError } from "@firebase/util";
import validationRules from "@/mixins/validation";
import FormErrorMessage from "@/components/FormErrorMessage.vue";
import { mdiClose, mdiEye, mdiEyeOff } from "@mdi/js";

export default Vue.extend({
  name: "LoginPage",
  mixins: [validationRules],
  components: {
    FormErrorMessage,
  },
  data() {
    return {
      mdiClose,
      mdiEye,
      mdiEyeOff,
      isPasswordOn: false,
      isShowPassword: false,
      isFormValid: false,
      loading: false,
      loginData: {
        email: "",
        password: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      this.errorMessage = "";

      try {
        this.loading = true;
        await signInWithEmailAndPassword(getAuth(), this.loginData.email, this.loginData.password);
      } catch (error: unknown) {
        if (!(error instanceof FirebaseError)) {
          this.errorMessage = "Ooops, something went wrong... please try again";
          return;
        }

        if (error.code === "auth/wrong-password") {
          this.errorMessage = "Invalid password for the given email";
          return;
        }

        if (error.code === "auth/user-not-found") {
          this.errorMessage = "There is no user corresponding to the given email";
          return;
        }

        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
    async googleLogin() {
      this.errorMessage = "";

      try {
        this.loading = true;
        await signInWithPopup(getAuth(), new GoogleAuthProvider());
      } catch (error: unknown) {
        if (!(error instanceof FirebaseError)) {
          this.errorMessage = "Ooops, something went wrong... please try again";
          return;
        }

        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
    async loginWithMagicLink() {
      this.errorMessage = "";

      this.loading = true;
      const { error } = await api.user.magicLink({ email: this.loginData.email });
      this.loading = false;

      if (error) {
        this.errorMessage = error.text;
        return;
      }

      this.$router.push({ name: "MagicLink", query: { email: this.loginData.email } });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.login-page {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 0;

  @include breakpoint(xsmall) {
    margin: 0 -20px;
    padding: 0;
  }

  .card {
    @include sign-card;

    .login-type-switch {
      display: flex;
      gap: 20px;
      align-items: center;
      font-size: var(--font-small);

      .v-input--switch {
        margin: 0;
      }
    }
  }
}
</style>
