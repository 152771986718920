<template>
  <div class="claim-confirmation">
    <div class="step-title">
      <span>{{ step }}. </span>
      <span v-if="claimProgress.isConfirmed">
        We have confirmed that you are the owner of the profile, click next to register
      </span>
      <span v-else>
        It was not possible to confirm the claim of this provider. If you have problems, please
        contact us
      </span>
    </div>

    <img src="/img/claim-profile-confirmation-img.svg" class="bg-img" alt="" />

    <div v-if="claimProgress.isConfirmed" class="actions">
      <AppButton :loading="stepLoading" @click="$emit('confirm')" outlined width="144px">
        Next
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { IClaimProgress } from "@/types";

export default Vue.extend({
  name: "ClaimConfirmation",
  props: {
    step: {
      type: Number,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    claimProgress: {
      type: Object as () => IClaimProgress,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-confirmation {
  .step-title {
    margin-bottom: 50px;
  }

  .bg-img {
    margin: 0 auto 64px;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
}
</style>
