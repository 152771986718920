<template>
  <article @click="$emit('click')" class="claim-doctor-suggest-card">
    <div class="content">
      <div class="photo">
        <img
          :src="
            MediaActions.getImageById(doctor.avatar, '200', '200') || doctor.gender === 'f'
              ? '/img/doctor-female.svg'
              : '/img/doctor-male.svg'
          "
          height="100"
          width="100"
          class="image"
          alt=""
        />
      </div>

      <div class="information">
        <h3 class="doctor-title">{{ doctor.name }} {{ doctor.degrees }}</h3>

        <ul class="doctor-info-list">
          <li class="info-list-item">
            <v-icon size="16" color="var(--color-green-secondary)">{{ mdiStethoscope }}</v-icon>
            {{ doctor.type }}
          </li>
          <li class="info-list-item">
            {{ doctor.gender === "m" ? "Male" : "Female" }}
          </li>
          <li class="info-list-item">
            {{ doctor.age }}
          </li>
        </ul>

        <div class="specialties">
          <span class="specialties-title">Specialties:</span>
          {{ doctor.specialties }}
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import Vue from "vue";
import { IClaimProgressOptionCard } from "@/types";
import { mdiStethoscope } from "@mdi/js";
import { MediaActions } from "@/services/images";

export default Vue.extend({
  name: "ClaimSuggestCardDoctor",
  props: {
    doctor: {
      type: Object as () => IClaimProgressOptionCard,
      required: true,
    },
  },
  data() {
    return {
      mdiStethoscope,
      MediaActions,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.claim-doctor-suggest-card {
  padding: 16px;
  background-color: var(--color-white);
  border: var(--border-panel-purple);
  border-radius: 24px;
  cursor: pointer;
}

.content {
  display: flex;
  gap: 16px;
}

.photo {
  .image {
    width: 92px;
    min-width: 92px;
    height: 92px;
    border-radius: 8px;
  }

  @include breakpoint(xsmall) {
    display: none;
  }
}

.information {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  overflow: hidden;
}

.doctor-title {
  overflow: hidden;
  color: var(--color-green-secondary);
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-big);
  line-height: 1.2;
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;

  @include breakpoint(xsmall) {
    width: 100%;
    white-space: normal;
  }
}

.doctor-info-list {
  display: flex;
  gap: 16px;
  padding: 0;
  color: var(--color-green-secondary);

  .info-list-item {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;

    &:not(:last-child)::after {
      position: absolute;
      top: 50%;
      left: 100%;
      display: block;
      width: 4px;
      height: 4px;
      background-color: var(--color-green-secondary);
      border-radius: 50%;
      transform: translate(100%, -25%);
      content: "";
    }
  }
}

.specialties {
  color: var(--color-grey-primary);
}

.specialties-title {
  font-weight: var(--font-weight-bolder);
}
</style>
