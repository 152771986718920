<template>
  <v-text-field
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    :type="type"
    :rules="rules"
    :autocomplete="autocomplete"
    :clearable="clearable"
    :dense="dense"
    :height="height"
    hide-details="auto"
    outlined
    class="app-input"
    @keyup.enter="$emit('pressEnter')"
    @input="$emit('input', inputValue)"
    @click:append="$emit('click:append')"
  />
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppInput",
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    rules: {
      type: Array,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    appendIcon: {
      type: String,
      required: false,
      default: "",
    },
    autocomplete: {
      type: String,
      required: false,
      default: "off",
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    inputValue() {
      this.$emit("update:value", this.inputValue);
    },
  },
});
</script>

<style lang="scss" scoped>
.app-input::v-deep {
  border-radius: 8px;
}
</style>
