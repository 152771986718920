import Vue from "vue";
import Vuex from "vuex";
import organization from "@/store/modules/organization";
import provider from "@/store/modules/provider";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    organization,
    provider,
  },
  state: {
    user: null,
    currentView: "",
  },
  getters: {
    getUser: (state) => state.user,
    getCurrentView: (state) => state.currentView,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_CURRENT_VIEW: (state, currentView) => {
      state.currentView = currentView;
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      commit("SET_USER", user);
    },
    setCurrentView: ({ commit }, currentView) => {
      commit("SET_CURRENT_VIEW", currentView);
    },
  },
});
