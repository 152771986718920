<template>
  <v-dialog
    v-model="isOpen"
    :width="width"
    :max-width="maxWidth"
    overlay-color="var(--color-grey-secondary)"
    overlay-opacity="0.6"
    content-class="app-info-dialog"
    @click:outside="$emit('close')"
  >
    <template #activator="{}">
      <slot name="activator" />
    </template>
    <div class="content">
      <span v-if="$slots.title" class="dialog-title">
        <slot name="title" />
      </span>
      <p class="dialog-text">
        <slot name="text" />
      </p>
    </div>
    <button class="action" @click="$emit('close')">Ok</button>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppInfoDialog",
  props: {
    isInfoDialogOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "504",
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isOpen: this.isInfoDialogOpen,
    };
  },
  watch: {
    isInfoDialogOpen(value: boolean) {
      this.isOpen = value;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.closeDialogHandler);
  },
  destroyed() {
    document.removeEventListener("keydown", this.closeDialogHandler);
  },
  methods: {
    closeDialogHandler(e: KeyboardEvent) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .app-info-dialog {
    background-color: var(--color-white);
    border-radius: var(--default-border-radius);
  }

  .content {
    padding: 24px;
  }

  .dialog-title {
    display: block;
    margin-bottom: 24px;
    font-weight: var(--font-weight-bolder);
  }

  .dialog-text {
    margin: 0;
  }

  .action {
    padding: 20px;
    color: var(--color-white);
    font-weight: var(--font-weight-bolder);
    text-align: center;
    background-color: var(--color-blue-primary);
  }
}
</style>
