import { callProfileApi } from "@/api/apiHelper";
import { IClaimProgress, IClaimProgressOptionCard, IFetchResponse } from "@/types";

const claimUrl = "/claim";

export const start = async (npi: string): Promise<IFetchResponse<IClaimProgress>> => {
  return await callProfileApi("post", `${claimUrl}`, {
    params: { npi },
  });
};

export const update = async (id: string, req: object): Promise<IFetchResponse<IClaimProgress>> => {
  return await callProfileApi("put", `${claimUrl}/${id}`, req);
};

export const confirm = async (id: string): Promise<IFetchResponse<IClaimProgress>> => {
  return await callProfileApi("post", `${claimUrl}/${id}/confirm`);
};

export const findProvider = async (
  keyword: string
): Promise<IFetchResponse<IClaimProgressOptionCard[]>> => {
  return await callProfileApi("get", "claim/providers", {
    params: { keyword },
  });
};
