import { profileApi, searchApi } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { IFetchResponse } from "@/types";

export const callProfileApi = async (method: string, url: string, ...args: unknown[]) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: AxiosResponse = await profileApi[method](url, ...args);
    return { data: response?.data, error: null } as IFetchResponse;
  } catch (error: any | unknown) {
    return {
      data: null,
      error: {
        text: error.response?.data,
      },
    } as IFetchResponse;
  }
};

export const callSearchApi = async (method: string, url: string, ...args: unknown[]) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: AxiosResponse = await searchApi[method](url, ...args);
    return { data: response?.data, error: null } as IFetchResponse;
  } catch (error: any | unknown) {
    return {
      data: null,
      error: {
        text: error.response?.data,
      },
    } as IFetchResponse;
  }
};
