<template>
  <div class="app-row-field">
    <div class="row-field">
      <slot />
      <div class="action">
        <slot name="action" />
      </div>
    </div>
    <slot name="expand-info"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppRowField",
});
</script>

<style lang="scss" scoped>
.app-row-field {
  width: 100%;
  padding: 16px 0;
  border-bottom: var(--border-panel-purple);

  .row-field {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
  }

  .action {
    display: flex;
    justify-content: center;
    width: 36px;
    margin-left: auto;
  }
}
</style>
